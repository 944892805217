<template>
    <section style="background-image: url('/q-eb1b2c69.png')" class="lazy-background-image lazy-background-image-backgroundImage lazy-background-image-loaded build build-visible">
        <div class="build-lines">
            <div class="build-line">
                <div class="build-line-mask">
                    <div></div>
                </div>
            </div>
            <div class="build-line">
                <div class="build-line-mask">
                    <div></div>
                </div>
            </div>
        </div>
        <div class="square-frames">
            <div class="square-frames-item">
                <div></div>
            </div>
            <div class="square-frames-item">
                <div></div>
            </div>
            <div class="square-frames-item">
                <div></div>
            </div>
        </div>
        <div class="build-container">
            <div class="section-header section-header-left">
                <h2 class="section-header-title section-header-title-h3">
                    <div class="section-header-title-desktop">
                        <span>
                            Решения и <b>Приоритеты</b>
                        </span>
                    </div>
                    <div class="section-header-title-mobile">
                        <span>Решения и</span>
                        <span><b>Приоритеты</b></span>
                    </div>
                </h2>
                <p class="section-header-description" style="max-width: 100%;">Работа с криптовалютой это сложный процесс, но мы в Coin Fuze предлагаем решения, которые помогут вашей криптовалюте работать наиболее эффективно и безопасно.</p>
            </div>
            
            <div class="build-boxes">
                <div class="card card-left build-collaborate">
                    <img alt="Background dots" height="1"
                        src="/q-ede3aff2.png" style="" width="1"
                        class="lazy-image lazy-image-hidden build-collaborate-dots"
                       >
                    <div class="card-heading">
                        <svg fill="none" height="28" viewBox="0 0 28 28" width="28"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M9.03376 16.8476L8.72381 15.996C8.18664 14.5202 6.55478 13.7592 5.07894 14.2964C3.60309 14.8336 2.84214 16.4654 3.3793 17.9413L4.20229 20.2024C4.86341 22.0188 6.87186 22.9554 8.68829 22.2942L9.23644 22.0947C11.0529 21.4336 11.9894 19.4252 11.3283 17.6087L11.2143 17.2955C10.9939 16.69 10.3244 16.3778 9.71895 16.5982L9.03376 16.8476Z"
                                fill="#E2E8FF" fill-opacity="0.2"></path>
                            <path
                                d="M18.9669 16.8476L19.2769 15.996C19.814 14.5202 21.4459 13.7592 22.9217 14.2964C24.3976 14.8336 25.1585 16.4654 24.6214 17.9413L23.7984 20.2024C23.1373 22.0188 21.1288 22.9554 19.3124 22.2942L18.7642 22.0947C16.9478 21.4336 16.0113 19.4252 16.6724 17.6087L16.7864 17.2955C17.0068 16.69 17.6762 16.3778 18.2817 16.5982L18.9669 16.8476Z"
                                fill="#E2E8FF" fill-opacity="0.2"></path>
                            <path
                                d="M10.4773 12.5914C11.0364 11.1948 12.4023 10.2083 13.9987 10.2083C15.595 10.2083 16.9609 11.1948 17.5201 12.5914M22.9376 10.4244C21.5183 6.87917 18.0509 4.375 13.9987 4.375C9.94645 4.375 6.4791 6.87917 5.05971 10.4244M8.72381 15.996L9.03376 16.8476L9.71895 16.5982C10.3244 16.3778 10.9939 16.69 11.2143 17.2955L11.3283 17.6087C11.9894 19.4252 11.0529 21.4336 9.23644 22.0947L8.68829 22.2942C6.87186 22.9554 4.86341 22.0188 4.20229 20.2024L3.3793 17.9413C2.84214 16.4654 3.60309 14.8336 5.07894 14.2964C6.55478 13.7592 8.18664 14.5202 8.72381 15.996ZM19.2769 15.996L18.9669 16.8476L18.2817 16.5982C17.6762 16.3778 17.0068 16.69 16.7864 17.2955L16.6724 17.6087C16.0113 19.4252 16.9478 21.4336 18.7642 22.0947L19.3124 22.2942C21.1288 22.9554 23.1373 22.0188 23.7984 20.2024L24.6214 17.9413C25.1585 16.4654 24.3976 14.8336 22.9217 14.2964C21.4459 13.7592 19.814 14.5202 19.2769 15.996Z"
                                stroke="#E2E8FF" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5">
                            </path>
                            <path
                                d="M7.76953 11.5073C8.7588 9.03634 11.1754 7.29102 13.9997 7.29102C16.824 7.29102 19.2406 9.03634 20.2299 11.5073"
                                opacity="0.5" stroke="#E2E8FF" stroke-linecap="round" stroke-linejoin="round"
                                stroke-width="1.5"></path>
                        </svg>
                        <div class="card-title">
                            Coin Fuze<br/>
                            We Make Crypto Work For Everyone
                        </div>
                        <div class="card-description">
                            Разработан предпринимателями, ИТ-специалистами и профессиональными трейдерами.
                        </div>
                    </div>
                    <div class="card-box h-68">
                        <img src="/deciosion-icon-1.png" alt="">
                        <div class="card-title">
                            Безопасность
                        </div>
                        <div class="card-description">
                            Мы придаем большое значение надежности и безопасности наших данных
                            Все операции и результаты торгов сохраняются и защищены, чтобы вы могли быть уверены в их достоверности.
                            Мы используем ведущие протоколы, сочетающие алгоритмы шифрования и цифровые сертификаты, такие как https и ssl, ssh, ipec и другие.
                        </div>
                    </div>
                </div>
                <div class="card card-left">
                    <img alt height="1" loading="lazy" src="/q-c737e4ce.png"
                        style="" width="1" class="lazy-image lazy-image-hidden build-crafted-dots"
                       >
                    <div class="card-heading">
                        <!--qv q:s q:sref=23 q:key=icon--><svg fill="none" height="28" viewBox="0 0 28 28" width="28"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M7.875 11.1768C7.875 10.7581 8.09933 10.3715 8.46284 10.1638L13.4212 7.33047C13.7798 7.12552 14.2202 7.12552 14.5788 7.33047L19.5372 10.1638C19.9007 10.3715 20.125 10.7581 20.125 11.1768V16.8227C20.125 17.2413 19.9007 17.6279 19.5372 17.8356L14.5788 20.669C14.2202 20.8739 13.7798 20.8739 13.4212 20.669L8.46284 17.8356C8.09933 17.6279 7.875 17.2413 7.875 16.8227V11.1768Z"
                                fill="#E2E8FF" fill-opacity="0.2"></path>
                            <path
                                d="M20.125 10.4997L14 13.9997M14 13.9997L7.875 10.4997M14 13.9997V20.9997M20.125 11.1768V16.8227C20.125 17.2413 19.9007 17.6279 19.5372 17.8356L14.5788 20.669C14.2202 20.8739 13.7798 20.8739 13.4212 20.669L8.46284 17.8356C8.09933 17.6279 7.875 17.2413 7.875 16.8227V11.1768C7.875 10.7581 8.09933 10.3715 8.46284 10.1638L13.4212 7.33047C13.7798 7.12552 14.2202 7.12552 14.5788 7.33047L19.5372 10.1638C19.9007 10.3715 20.125 10.7581 20.125 11.1768Z"
                                stroke="#E2E8FF" stroke-width="1.5"></path>
                            <path
                                d="M22.4987 4.66634C22.4987 5.12658 22.8718 5.49967 23.332 5.49967C23.7923 5.49967 24.1654 5.12658 24.1654 4.66634C24.1654 4.2061 23.7923 3.83301 23.332 3.83301C22.8718 3.83301 22.4987 4.2061 22.4987 4.66634ZM3.83203 4.66634C3.83203 5.12658 4.20513 5.49967 4.66536 5.49967C5.1256 5.49967 5.4987 5.12658 5.4987 4.66634C5.4987 4.2061 5.1256 3.83301 4.66536 3.83301C4.20513 3.83301 3.83203 4.2061 3.83203 4.66634ZM22.4987 23.333C22.4987 23.7932 22.8718 24.1663 23.332 24.1663C23.7923 24.1663 24.1654 23.7932 24.1654 23.333C24.1654 22.8728 23.7923 22.4997 23.332 22.4997C22.8718 22.4997 22.4987 22.8728 22.4987 23.333ZM3.83203 23.333C3.83203 23.7932 4.20513 24.1663 4.66536 24.1663C5.1256 24.1663 5.4987 23.7932 5.4987 23.333C5.4987 22.8728 5.1256 22.4997 4.66536 22.4997C4.20513 22.4997 3.83203 22.8728 3.83203 23.333Z"
                                fill="#E2E8FF" stroke="#E2E8FF" stroke-linecap="square" stroke-width="0.5"></path>
                            <path
                                d="M24.9562 13.4641C24.6602 13.7601 24.6602 14.2399 24.9562 14.5359C25.2522 14.8319 25.732 14.8319 26.028 14.5359C26.324 14.2399 26.324 13.7601 26.028 13.4641C25.732 13.1681 25.2522 13.1681 24.9562 13.4641ZM13.4641 1.97198C13.1681 2.26795 13.1681 2.74782 13.4641 3.04379C13.7601 3.33976 14.2399 3.33976 14.5359 3.04379C14.8319 2.74782 14.8319 2.26795 14.5359 1.97198C14.2399 1.67601 13.7601 1.67601 13.4641 1.97198ZM13.4641 24.9562C13.1681 25.2522 13.1681 25.732 13.4641 26.028C13.7601 26.324 14.2399 26.324 14.5359 26.028C14.8319 25.732 14.8319 25.2522 14.5359 24.9562C14.2399 24.6602 13.7601 24.6602 13.4641 24.9562ZM1.97198 13.4641C1.67601 13.7601 1.67601 14.2399 1.97198 14.5359C2.26795 14.8319 2.74782 14.8319 3.04379 14.5359C3.33976 14.2399 3.33976 13.7601 3.04379 13.4641C2.74782 13.1681 2.26795 13.1681 1.97198 13.4641Z"
                                fill="#E2E8FF" opacity="0.5" stroke="#E2E8FF" stroke-linecap="square"
                                stroke-width="0.5"></path>
                        </svg>
                        <div class="card-title">
                            Наша миссия
                        </div>
                        <div class="card-description">
                            Вместе мы создаем международную финансовую экосистему и меняем подход к инвестированию. Здесь мы предоставляем возможности и преимущества, которые необходимы для лучшей жизни и построению будущего для тех, кого Вы любите. <br/>
                            Мы стремимся, чтобы успех был реальностью для каждого.
                        </div>
                    </div>
                    <div class="card-box h-44">
                        <img src="/deciosion-icon-2.png" alt="">
                        <div class="card-title">
                            Доверие
                        </div>
                        <div class="card-description">
                            CoinFuze сотрудничает исключительно 
                            с ведущими биржами и работает в
                            полном соответствии с 
                            лицензированными регулирующими 
                            органами.
                        </div>
                    </div>
                </div>
            </div>
            <div class="build-features">
                <div class="build-features-heading">
                    <svg fill="none" height="28" viewBox="0 0 29 28" width="29"
                        xmlns="http://www.w3.org/2000/svg">
                        <circle cx="17.5" cy="17" fill="#E2E8FF" fill-opacity="0.1" r="6" stroke="#E2E8FF"
                            stroke-linejoin="round"></circle>
                        <circle cx="11.5" cy="17" fill="#E2E8FF" fill-opacity="0.1" r="6" stroke="#E2E8FF"
                            stroke-linejoin="round"></circle>
                        <circle cx="14.5" cy="11" fill="#E2E8FF" fill-opacity="0.1" r="6" stroke="#E2E8FF"
                            stroke-linejoin="round"></circle>
                        <path
                            d="M11.5495 16.2257C11.7933 14.3336 12.9185 12.7176 14.5 11.8027C16.0815 12.7176 17.2067 14.3336 17.4505 16.2257C16.5793 16.7187 15.5725 17 14.5 17C13.4275 17 12.4208 16.7187 11.5495 16.2257Z"
                            fill="#E2E8FF" fill-opacity="0.32"></path>
                    </svg>
                    
                    <div class="build-features-heading-title"><span>Все что вам нужно </span> в одной платформе Coin Fuze.
                    </div>
                    <div class="build-features-heading-description">От первых инвестиций - до первой прибыли.</div>
                </div>
                <div class="build-features-items swiper" q:id="2u">
                    <div class="swiper-wrapper">
                        <div class="build-features-item swiper-slide">
                            <div class="build-features-item-title">
                                <!--qv q:key=4q_1--><svg fill="none" height="20" viewBox="0 0 20 20" width="20"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M18.125 10C18.125 5.70312 14.9653 3.125 10 3.125C5.03472 3.125 1.875 5.70312 1.875 10C1.875 11.114 2.64212 13.0046 2.76208 13.2935C2.77285 13.3194 2.78351 13.3431 2.79317 13.3694C2.87574 13.5947 3.21435 14.7939 1.875 16.569C3.68056 17.4284 5.59805 16.0156 5.59805 16.0156C6.92469 16.7164 8.5032 16.875 10 16.875C14.9653 16.875 18.125 14.2969 18.125 10Z"
                                        stroke="#E2E8FF" stroke-linecap="square" stroke-linejoin="round"
                                        stroke-width="1.5"></path>
                                    <path
                                        d="M5.58334 10.0002C5.58334 10.3684 5.88182 10.6668 6.25001 10.6668C6.6182 10.6668 6.91668 10.3684 6.91668 10.0002C6.91668 9.63197 6.6182 9.3335 6.25001 9.3335C5.88182 9.3335 5.58334 9.63197 5.58334 10.0002ZM9.33334 10.0002C9.33334 10.3684 9.63182 10.6668 10 10.6668C10.3682 10.6668 10.6667 10.3684 10.6667 10.0002C10.6667 9.63197 10.3682 9.3335 10 9.3335C9.63182 9.3335 9.33334 9.63197 9.33334 10.0002ZM13.0833 10.0002C13.0833 10.3684 13.3818 10.6668 13.75 10.6668C14.1182 10.6668 14.4167 10.3684 14.4167 10.0002C14.4167 9.63197 14.1182 9.3335 13.75 9.3335C13.3818 9.3335 13.0833 9.63197 13.0833 10.0002Z"
                                        fill="#E2E8FF" stroke="#E2E8FF" stroke-linecap="square" stroke-width="0.5">
                                    </path>
                                </svg>
                                Чат и Поддержка</div>
                            <div class="build-features-item-description">Клиентская поддержка мгновенно обрабатывает ваши запросы и всегда на связи.</div>
                        </div>
                        <div class="build-features-item swiper-slide">
                            <div class="build-features-item-title">
                                <!--qv q:key=4q_1--><svg fill="none" height="20" viewBox="0 0 20 20" width="20"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M13.75 6.6665V9.1665C13.75 9.62674 13.3769 9.99984 12.9166 9.99984H7.08331C6.62308 9.99984 6.24998 10.3729 6.24998 10.8332M6.24998 10.8332V6.6665M6.24998 10.8332V13.3332M8.54165 4.58317C8.54165 5.84882 7.51563 6.87484 6.24998 6.87484C4.98433 6.87484 3.95831 5.84882 3.95831 4.58317C3.95831 3.31752 4.98433 2.2915 6.24998 2.2915C7.51563 2.2915 8.54165 3.31752 8.54165 4.58317ZM16.0416 4.58317C16.0416 5.84882 15.0156 6.87484 13.75 6.87484C12.4843 6.87484 11.4583 5.84882 11.4583 4.58317C11.4583 3.31752 12.4843 2.2915 13.75 2.2915C15.0156 2.2915 16.0416 3.31752 16.0416 4.58317ZM8.54165 15.4165C8.54165 16.6822 7.51563 17.7082 6.24998 17.7082C4.98433 17.7082 3.95831 16.6822 3.95831 15.4165C3.95831 14.1509 4.98433 13.1248 6.24998 13.1248C7.51563 13.1248 8.54165 14.1509 8.54165 15.4165Z"
                                        stroke="#E2E8FF" stroke-linejoin="round" stroke-width="1.5"></path>
                                </svg>
                                Реинвестирование</div>
                            <div class="build-features-item-description">Хотите обеспечить бесперебойную работу алгоритма?
Реинвестируйте доход снова и получайте еще больше прибыли.</div>
                        </div>
                        <div class="build-features-item swiper-slide">
                            <div class="build-features-item-title">
                                <!--qv q:key=4q_1--><svg fill="none" height="20" viewBox="0 0 20 20" width="20"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M11.0417 3.9585H3.125C2.66476 3.9585 2.29167 4.33159 2.29167 4.79183V15.2085C2.29167 15.6687 2.66476 16.0418 3.125 16.0418H11.0417M13.5378 3.9585H16.8712C17.3314 3.9585 17.7045 4.33159 17.7045 4.79183V15.2085C17.7045 15.6687 17.3314 16.0418 16.8712 16.0418H13.5378M13.5378 3.9585V2.0835M13.5378 3.9585V16.0418M13.5378 16.0418V17.9168M7.08333 7.91683L9.16667 10.0002L7.08333 12.0835"
                                        stroke="#E2E8FF" stroke-linecap="round" stroke-linejoin="round"
                                        stroke-width="1.5"></path>
                                </svg>
                                Высокодоходные Инструменты</div>
                            <div class="build-features-item-description">Получайте больше прибыли при меньших рисках инвестируя в планы от 6 месяцев.</div>
                        </div>
                        <div class="build-features-item swiper-slide">
                            <div class="build-features-item-title">
                                <!--qv q:key=4q_1--><svg fill="none" height="20" viewBox="0 0 20 20" width="20"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M9.79167 13.5417H16.875M9.79167 6.45833H16.875M3.125 7.1875L4.51389 8.125L7.29167 4.375M3.125 14.6875L4.51389 15.625L7.29167 11.875"
                                        stroke="#E2E8FF" stroke-linecap="round" stroke-linejoin="round"
                                        stroke-width="1.5"></path>
                                </svg>
                                Партнерская Программа</div>
                            <div class="build-features-item-description">Дополнительный способ увеличить ваш доход и расширить ваши инвестиционные возможности.  </div>
                        </div>
                        <div class="build-features-item swiper-slide">
                            <div class="build-features-item-title">
                                <!--qv q:key=4q_1--><svg fill="none" height="20" viewBox="0 0 20 20" width="20"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M3.125 10.625H6.72517C7.01771 12.1672 8.37267 13.3333 10 13.3333C11.6273 13.3333 12.9823 12.1672 13.2748 10.625L16.875 10.625M16.0417 16.875H3.95834C3.4981 16.875 3.125 16.5019 3.125 16.0417L3.12504 3.95834C3.12504 3.4981 3.49814 3.125 3.95837 3.125L16.0417 3.125C16.5019 3.125 16.875 3.4981 16.875 3.95833V16.0417C16.875 16.5019 16.5019 16.875 16.0417 16.875Z"
                                        stroke="#E2E8FF" stroke-linecap="square" stroke-linejoin="round"
                                        stroke-width="1.5"></path>
                                </svg>
                                Бонусы Команд</div>
                            <div class="build-features-item-description">Получайте дополнительные бонусы от активного инвестиционного оборота команды ваших партнеров. </div>
                        </div>
                        <div class="build-features-item swiper-slide">
                            <div class="build-features-item-title">
                                <!--qv q:key=4q_1--><svg fill="none" height="20" viewBox="0 0 20 20" width="20"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path clip-rule="evenodd"
                                        d="M10.8333 5.8335C11.1785 5.8335 11.4583 6.11332 11.4583 6.4585C11.4583 8.48 11.9049 9.79288 12.7228 10.6107C13.5406 11.4286 14.8535 11.8752 16.875 11.8752C17.2202 11.8752 17.5 12.155 17.5 12.5002C17.5 12.8453 17.2202 13.1252 16.875 13.1252C14.8535 13.1252 13.5406 13.5717 12.7228 14.3896C11.9049 15.2074 11.4583 16.5203 11.4583 18.5418C11.4583 18.887 11.1785 19.1668 10.8333 19.1668C10.4882 19.1668 10.2083 18.887 10.2083 18.5418C10.2083 16.5203 9.76176 15.2074 8.94391 14.3896C8.12606 13.5717 6.81317 13.1252 4.79167 13.1252C4.44649 13.1252 4.16667 12.8453 4.16667 12.5002C4.16667 12.155 4.44649 11.8752 4.79167 11.8752C6.81317 11.8752 8.12606 11.4286 8.94391 10.6107C9.76176 9.79288 10.2083 8.48 10.2083 6.4585C10.2083 6.11332 10.4882 5.8335 10.8333 5.8335ZM10.8333 10.0073C10.5825 10.5746 10.2506 11.0718 9.82779 11.4946C9.40494 11.9175 8.90774 12.2494 8.34049 12.5002C8.90774 12.751 9.40494 13.0829 9.82779 13.5057C10.2506 13.9286 10.5825 14.4258 10.8333 14.993C11.0841 14.4258 11.416 13.9286 11.8389 13.5057C12.2617 13.0829 12.7589 12.751 13.3262 12.5002C12.7589 12.2494 12.2617 11.9175 11.8389 11.4946C11.416 11.0718 11.0841 10.5746 10.8333 10.0073Z"
                                        fill="#E2E8FF" fill-rule="evenodd"></path>
                                    <path
                                        d="M5 4.5835C5 4.35338 4.81345 4.16683 4.58333 4.16683C4.35322 4.16683 4.16667 4.35338 4.16667 4.5835C4.16667 5.40069 3.98566 5.894 3.68975 6.18991C3.39384 6.48582 2.90053 6.66683 2.08333 6.66683C1.85322 6.66683 1.66667 6.85338 1.66667 7.0835C1.66667 7.31361 1.85322 7.50016 2.08333 7.50016C2.90053 7.50016 3.39384 7.68117 3.68975 7.97708C3.98566 8.27299 4.16667 8.7663 4.16667 9.5835C4.16667 9.81361 4.35322 10.0002 4.58333 10.0002C4.81345 10.0002 5 9.81361 5 9.5835C5 8.7663 5.18101 8.27299 5.47692 7.97708C5.77283 7.68117 6.26614 7.50016 7.08333 7.50016C7.31345 7.50016 7.5 7.31361 7.5 7.0835C7.5 6.85338 7.31345 6.66683 7.08333 6.66683C6.26614 6.66683 5.77283 6.48582 5.47692 6.18991C5.18101 5.894 5 5.40069 5 4.5835Z"
                                        fill="#E2E8FF"></path>
                                    <path
                                        d="M9.16667 1.25016C9.16667 1.02004 8.98012 0.833496 8.75 0.833496C8.51988 0.833496 8.33333 1.02004 8.33333 1.25016C8.33333 1.778 8.21598 2.06298 8.05607 2.2229C7.89615 2.38281 7.61118 2.50016 7.08333 2.50016C6.85322 2.50016 6.66667 2.68671 6.66667 2.91683C6.66667 3.14695 6.85322 3.3335 7.08333 3.3335C7.61118 3.3335 7.89615 3.45085 8.05607 3.61076C8.21598 3.77068 8.33333 4.05566 8.33333 4.5835C8.33333 4.81361 8.51988 5.00016 8.75 5.00016C8.98012 5.00016 9.16667 4.81361 9.16667 4.5835C9.16667 4.05566 9.28402 3.77068 9.44393 3.61076C9.60385 3.45085 9.88883 3.3335 10.4167 3.3335C10.6468 3.3335 10.8333 3.14695 10.8333 2.91683C10.8333 2.68671 10.6468 2.50016 10.4167 2.50016C9.88883 2.50016 9.60385 2.38281 9.44393 2.2229C9.28402 2.06298 9.16667 1.778 9.16667 1.25016Z"
                                        fill="#E2E8FF"></path>
                                </svg>
                                Прозрачность операций</div>
                            <div class="build-features-item-description"> Мы предоставляем подробную и детальную информацию о наших операциях, доходах и результатах.  </div>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        <div class="build-features-heading mt-45" style="margin-top: 45px;">
            <div class="build-features-heading-title custom">
                <img src="/partners-icon.png" alt="">
                Ищете надежную платформу?
            </div>
            <div class="build-features-heading-description" style="font-size: 15px;">Присоединяйтесь к более чем 1500 клиентов по всему миру, которые выбрали нашу платформу Coin Fuze.</div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'DecisionPriorComponent'
    }
</script>

<style>
.lazy-background-image {
    transition: 1s cubic-bezier(.6,.6,0,1) opacity
}

.lazy-background-image-hidden {
    opacity: 0
}

.lazy-background-image-backgroundImage {
    background-image: var(--background-image);
    background-size: cover
}

.lazy-background-image-maskImage {
    -webkit-mask-image: var(--background-image);
    mask-image: var(--background-image);
    -webkit-mask-size: cover;
    mask-size: cover
}
.build {
    width: 1440px;
    height: 1570px;
    position: relative;
    padding: 184px 222px 0
}

.build:before {
    width: 230px;
    content: "";
    position: absolute;
    top: 0;
    right: 605px;
    background-image: linear-gradient(to top,transparent,#01011b);
    height: 148px;
    z-index: 1
}

.build-container {
    content-visibility: auto
}

.build .square-frames {
    margin-bottom: 20px;
    margin-left: -12px
}

.build .press-d {
    margin: 25px -12px 26px auto
}

.build-boxes {
    height: 590px;
    display: grid;
    grid-template-columns: repeat(10,1fr);
    grid-gap: 24px;
    gap: 24px;
    margin-top: 50px;
}

.build-boxes .build-collaborate {
    grid-column: 1/7
}

.build-boxes .card:last-child {
    grid-column: 7/11
}

@media (max-width: 996px) {
    .build {
        height:1972px;
        background-size: 390px auto!important;
        background-position: center;
        background-repeat: no-repeat;
        padding: 108px 0 0
    }

    .build .square-frames {
        margin: 0 auto 20px
    }

    .build .section-header {
        align-items: center;
        margin-bottom: 32px;
        text-align: center
    }

    .build-boxes {
        grid-template-columns: repeat(1,1fr);
        height: auto;
        height: initial;
        max-width: 350px;
        margin: 0 auto;
        gap: 20px
    }

    .build-boxes>* {
        grid-column: unset!important
    }
}
.build-line {
    position: absolute;
    top: 0;
    border-right: 1px solid rgba(226, 232, 255, .12);
    border-bottom: 1px solid rgba(226, 232, 255, .12);
    border-bottom-right-radius: 12px
}

.build-line:nth-child(1) {
    width: 300px;
    height: 228px;
    left: 306px
}

.build-line:nth-child(2) {
    width: 220px;
    height: 164px;
    left: 614px
}

.build-line:nth-child(2) .build-line-mask div {
    animation-name: build-line-rotate-2
}

@keyframes build-line-rotate-2 {

    40%,
    to {
        transform: translateZ(0) rotate(1turn)
    }
}

.build-line-mask {
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask-composite: xor;
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% + 1px);
    height: calc(100% + 1px);
    padding: 0 1px 1px 0;
    border-radius: inherit
}

.build-line-mask div {
    width: 500px;
    height: 500px;
    position: absolute;
    bottom: -100px;
    left: -100px;
    background: conic-gradient(rgba(109, 46, 255, 0), rgba(158, 122, 255, .3490196078) 6%, rgba(254, 139, 187, .6980392157) 10%, #FFBD7A 14.9%, rgba(109, 46, 255, 0) 15%)
}

@keyframes build-line-rotate {

    60%,
    to {
        transform: translateZ(0) rotate(1turn)
    }
}

.build-line:before,
.build-line:after {
    content: "";
    left: 50%;
    transform: translate(-50%);
    position: absolute;
    border-radius: 50%
}

.build-line:before {
    background: #05051E;
    width: 12px;
    bottom: -4px;
    height: 12px;
    z-index: 1
}

.build-line:after {
    background: #202039;
    width: 4px;
    height: 4px;
    z-index: 2;
    bottom: -2.5px
}

.build-visible .build-line-mask div {
    animation: build-line-rotate 15s linear infinite
}

@media (max-width: 996px) {
    .build-lines {
        display: none
    }
}
.square-frames {
    width: 88px;
    height: 88px;
    z-index: -1;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center
}

.square-frames-item {
    position: absolute;
    isolation: isolate;
    overflow: hidden;
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask-composite: xor;
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    padding: 1px
}

.square-frames-item:before {
    border: 1px solid rgba(226, 232, 255, .8);
    content: "";
    left: 0;
    top: 0;
    position: absolute;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    border-radius: inherit;
    pointer-events: none
}

.square-frames-item:nth-child(1) {
    width: 72px;
    height: 72px;
    border-radius: 16px;
    z-index: 2
}

.square-frames-item:nth-child(1):before {
    opacity: .3
}

.square-frames-item:nth-child(2) {
    width: 80px;
    height: 80px;
    border-radius: 20px;
    z-index: 1
}

.square-frames-item:nth-child(2):before {
    opacity: .2
}

.square-frames-item:nth-child(2) div {
    animation-duration: 12s
}

.square-frames-item:nth-child(3) {
    width: 88px;
    height: 88px;
    border-radius: 24px
}

.square-frames-item:nth-child(3):before {
    opacity: .1
}

.square-frames-item:nth-child(3) div {
    animation-duration: 14s
}

.square-frames-item div {
    width: 150%;
    height: 150%;
    position: absolute;
    top: -25%;
    left: -25%;
    opacity: .3;
    background: conic-gradient(transparent 0%, #fff 15%, transparent 15%);
    animation: square-frames-rotate 10s linear infinite
}

@keyframes square-frames-rotate {
    to {
        transform: rotate(1turn)
    }
}

@media (max-width: 996px) {
    .square-frames-item:nth-child(1) div {
        transform: rotate(-55deg)
    }

    .square-frames-item:nth-child(2) div {
        transform: rotate(35deg)
    }

    .square-frames-item:nth-child(3) div {
        transform: rotate(125deg)
    }

    .square-frames-item div {
        animation: none 0s ease 0s 1 normal none running;
        animation: initial
    }
}
.press-d {
    width: -moz-max-content;
    width: max-content;
    background-color: #05051e;
    padding: 0 12px
}

.press-d-content {
    position: relative;
    width: 217px;
    height: 20px
}

.press-d-image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0
}

.press-d-image div {
    background: linear-gradient(270deg, rgba(226, 232, 255, 0) 0%, rgb(226, 232, 255) 50%, rgba(226, 232, 255, 0) 100%);
    width: 44px;
    height: 20px;
    transform: translate(-44px);
    animation: press-d 8s cubic-bezier(.6, .6, 0, 1) infinite
}

@media (max-width: 996px) {
    .press-d {
        display: none
    }
}

@keyframes press-d {

    0%,
    90%,
    to {
        transform: translate(-44px)
    }

    30%,
    60% {
        transform: translate(217px)
    }
}
.build-collaborate-dots {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 130px;
    height: 82px
}
.card-description,
.card-title {
    font-feature-settings: "ss01"on, "cv10"on, "calt"off, "liga"off
}

.card-title {
    font-size: 18px;
    line-height: 28px;
    text-align: left;
    position: relative;
    z-index: 9;
}

.card-title {
    font-weight: 500
}

.card-description {
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    position: relative;
    z-index: 9;
}

.card {
    border-radius: 16px;
    background: rgba(226, 232, 255, .01);
    position: relative;
    width: 100%;
    isolation: isolate;
    overflow: hidden
}

.card:before {
    border: 1px solid rgba(226, 232, 255, .1);
    content: "";
    left: 0;
    top: 0;
    position: absolute;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    border-radius: inherit;
    pointer-events: none
}

.card-title {
    color: #e2e8ff;
    margin-bottom: 4px
}

.card-description {
    color: #e2e8ff8c;
}

.card-heading {
    padding: 22px 26px
}

.card-heading svg {
    margin-bottom: 8px
}

.card-center .card-heading {
    text-align: center
}

.card-center .card-title,
.card-center .card-description,
.card-center .card-heading svg {
    margin-left: auto;
    margin-right: auto
}

.card-box {
    width: 100%;
    border-radius: 10px;
    isolation: isolate;
    overflow: hidden;
    position: relative;
    padding: 22px 26px;
}

.card-box.h-44 {
    height: 44%;
}

.card-box.h-68 {
    height: 68%;
}

.card-box:before {
    border: 1px solid rgba(226, 232, 255, .1);
    content: "";
    left: 0;
    top: 0;
    position: absolute;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    border-radius: inherit;
    pointer-events: none
}

.card-box:before {
    z-index: 4
}

.card-box:after {
    border-radius: inherit;
    z-index: 3;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    box-shadow: 0 -28px 84px -24px #e2e8ff1f inset;
    background: #0a0a27;
}

.card-box img {
    position: relative;
    z-index: 9;
    margin-bottom: 15px;
}

.build-collaborate-box-input input {
    font-feature-settings: "ss01"on, "cv10"on, "calt"off, "liga"off
}

.build-collaborate-box-input input {
    font-size: 14px;
    line-height: 20px
}

.build-collaborate-box {
    height: 416px
}

.build-collaborate-box-messages {
    padding: 22px 26px;
    height: 100%;
    -webkit-mask-image: linear-gradient(to bottom, rgba(217, 217, 217, 0) 0%, #D9D9D9 10.34%, #D9D9D9 25%, #D9D9D9 50%, #D9D9D9 71.87%, rgba(217, 217, 217, 0) 88.94%);
    mask-image: linear-gradient(to bottom, rgba(217, 217, 217, 0) 0%, #D9D9D9 10.34%, #D9D9D9 25%, #D9D9D9 50%, #D9D9D9 71.87%, rgba(217, 217, 217, 0) 88.94%);
    overflow-y: auto;
    overflow-x: hidden
}

.build-collaborate-box-messages-body {
    padding-bottom: 32px
}

.build-collaborate-box-messages-body>div {
    transform: translateY(30px);
    opacity: 0;
    animation: build-collaborate-message-appear .8s cubic-bezier(.6, .6, 0, 1) forwards;
    margin-bottom: 16px
}

.build-collaborate-box-input {
    position: absolute;
    width: calc(100% - 20px);
    height: 42px;
    bottom: 10px;
    left: 10px;
    border-radius: 4px;
    background: rgba(226, 232, 255, .04);
    display: flex;
    align-items: center;
    padding: 0 7px;
    transition: transform .45s cubic-bezier(.6, .6, 0, 1), opacity .45s cubic-bezier(.6, .6, 0, 1);
    transition-delay: 1s
}

.build-collaborate-box-input:before {
    border: 1px solid rgba(226, 232, 255, .1);
    content: "";
    left: 0;
    top: 0;
    position: absolute;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    border-radius: inherit;
    pointer-events: none
}

.build-collaborate-box-input-disabled {
    transform: translateY(20px);
    opacity: 0
}

.build-collaborate-box-input input {
    flex: 1;
    padding: 7px;
    background-color: transparent;
    border: 0;
    color: #e2e8ffb3
}

.build-collaborate-box-input input::-moz-placeholder {
    color: #e2e8ff52
}

.build-collaborate-box-input input::placeholder {
    color: #e2e8ff52
}

.build-collaborate-box-input-emoji {
    width: 36px;
    height: auto
}

.build-collaborate-box .message-integration-footer {
    overflow-x: hidden
}

@keyframes build-collaborate-message-appear {
    to {
        transform: translateY(0);
        opacity: 1
    }
}

@media (max-width: 996px) {
    .build-collaborate-box-messages {
        padding: 12px 12px 22px
    }
}
.build-crafted-dots {
    position: absolute;
    top: 16px;
    right: 16px;
    width: 90px;
    height: 26px
}
.build-crafted-box {
    height: 416px;
    width: 372px
}

.build-crafted-box-circles {
    width: 100%;
    height: 100%;
    position: absolute;
    opacity: .32
}

.build-crafted-box-circles div:before {
    background: rgba(226, 232, 255, .1);
    border-radius: inherit;
    content: "";
    inset: 0;
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask-composite: xor;
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    padding: 1px;
    pointer-events: none;
    position: absolute
}

.build-crafted-box-circles-gradient div:before {
    background: conic-gradient(rgba(109, 46, 255, 0) 6.7%, rgba(158, 122, 255, .35) 20.8%, rgba(254, 139, 187, .7) 34.9%, #FFBD7A 49.99%, rgba(255, 189, 122, 0) 50%)
}

.build-crafted-box-circles div {
    position: absolute;
    border-radius: 50%
}

.build-crafted-box-circles div:nth-child(1) {
    width: 104px;
    height: 104px;
    top: calc(50% - 52px);
    left: calc(50% - 52px);
    transform: rotate(0)
}

.build-crafted-box-circles div:nth-child(2) {
    width: 136px;
    height: 136px;
    top: calc(50% - 68px);
    left: calc(50% - 68px);
    transform: rotate(-18deg)
}

.build-crafted-box-circles div:nth-child(3) {
    width: 168px;
    height: 168px;
    top: calc(50% - 84px);
    left: calc(50% - 84px);
    transform: rotate(-35deg)
}

.build-crafted-box-circles div:nth-child(4) {
    width: 200px;
    height: 200px;
    top: calc(50% - 100px);
    left: calc(50% - 100px);
    transform: rotate(-52deg)
}

.build-crafted-box-circles div:nth-child(5) {
    width: 232px;
    height: 232px;
    top: calc(50% - 116px);
    left: calc(50% - 116px);
    transform: rotate(-68deg)
}

.build-crafted-box-circles div:nth-child(6) {
    width: 264px;
    height: 264px;
    top: calc(50% - 132px);
    left: calc(50% - 132px);
    transform: rotate(-84deg)
}

.build-crafted-box-circles div:nth-child(7) {
    width: 296px;
    height: 296px;
    top: calc(50% - 148px);
    left: calc(50% - 148px);
    transform: rotate(-99deg)
}

.build-crafted-box-circles div:nth-child(8) {
    width: 328px;
    height: 328px;
    top: calc(50% - 164px);
    left: calc(50% - 164px);
    transform: rotate(-115deg)
}

.build-crafted-box-circles div:nth-child(9) {
    width: 360px;
    height: 360px;
    top: calc(50% - 180px);
    left: calc(50% - 180px);
    transform: rotate(-131deg)
}

.build-crafted-box-circles div:nth-child(10) {
    width: 392px;
    height: 392px;
    top: calc(50% - 196px);
    left: calc(50% - 196px);
    transform: rotate(-146deg)
}

.build-crafted-box-circles div:nth-child(11) {
    width: 424px;
    height: 424px;
    top: calc(50% - 212px);
    left: calc(50% - 212px);
    transform: rotate(-161deg)
}

.build-crafted-box-circles div:nth-child(12) {
    width: 456px;
    height: 456px;
    top: calc(50% - 228px);
    left: calc(50% - 228px);
    transform: rotate(-176deg)
}

.build-crafted-box-circles div:nth-child(13) {
    width: 488px;
    height: 488px;
    top: calc(50% - 244px);
    left: calc(50% - 244px);
    transform: rotate(-191deg)
}

.build-crafted-box-circles div:nth-child(14) {
    width: 520px;
    height: 520px;
    top: calc(50% - 260px);
    left: calc(50% - 260px);
    transform: rotate(-216deg)
}

.build-crafted-box-brands {
    top: 88px;
    left: 65px;
    width: 240px;
    height: 240px
}

.build-crafted-box-brands-item {
    width: 48px;
    height: 48px
}

.build-crafted-box-brands-item img {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 1px solid rgba(226, 232, 255, .08);
    background: rgba(226, 232, 255, .06)
}

.build-crafted-box-brands-item:nth-child(1) {
    top: 0
}

.build-crafted-box-brands-item:nth-child(5) {
    bottom: 0
}

.build-crafted-box-brands-item:nth-child(1),
.build-crafted-box-brands-item:nth-child(5) {
    left: 96px
}

.build-crafted-box-brands-item:nth-child(2),
.build-crafted-box-brands-item:nth-child(8) {
    top: 28px
}

.build-crafted-box-brands-item:nth-child(3),
.build-crafted-box-brands-item:nth-child(7) {
    top: 96px
}

.build-crafted-box-brands-item:nth-child(4),
.build-crafted-box-brands-item:nth-child(6) {
    top: 164px
}

.build-crafted-box-brands-item:nth-child(2),
.build-crafted-box-brands-item:nth-child(4) {
    right: 28px
}

.build-crafted-box-brands-item:nth-child(3) {
    right: 0
}

.build-crafted-box-brands-item:nth-child(6),
.build-crafted-box-brands-item:nth-child(8) {
    left: 28px
}

.build-crafted-box-bubbles {
    position: absolute
}

.build-crafted-box-bubbles div {
    border-radius: 50%;
    position: absolute;
    z-index: 1
}

.build-crafted-box-bubbles div:before {
    background-color: #080821;
    content: "";
    position: absolute;
    top: -6px;
    left: -6px;
    width: calc(100% + 12px);
    height: calc(100% + 12px);
    border-radius: 50%;
    z-index: -1
}

.build-crafted-box-bubbles-small {
    width: 284px;
    height: 284px;
    top: 66px;
    left: 43px
}

.build-crafted-box-bubbles-small div {
    width: 16px;
    height: 16px
}

.build-crafted-box-bubbles-small div:after {
    box-shadow: 0 0 0 1px #e2e8ff0f;
    background-color: #e2e8ff08;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%
}

.build-crafted-box-bubbles-small div:nth-child(1),
.build-crafted-box-bubbles-small div:nth-child(2) {
    top: 2px
}

.build-crafted-box-bubbles-small div:nth-child(1),
.build-crafted-box-bubbles-small div:nth-child(7) {
    left: 70px
}

.build-crafted-box-bubbles-small div:nth-child(2),
.build-crafted-box-bubbles-small div:nth-child(8) {
    right: 70px
}

.build-crafted-box-bubbles-small div:nth-child(4),
.build-crafted-box-bubbles-small div:nth-child(6) {
    right: 0
}

.build-crafted-box-bubbles-small div:nth-child(3),
.build-crafted-box-bubbles-small div:nth-child(5) {
    left: 1px
}

.build-crafted-box-bubbles-small div:nth-child(4),
.build-crafted-box-bubbles-small div:nth-child(6) {
    right: 1px
}

.build-crafted-box-bubbles-small div:nth-child(3),
.build-crafted-box-bubbles-small div:nth-child(4) {
    top: 70px
}

.build-crafted-box-bubbles-small div:nth-child(5),
.build-crafted-box-bubbles-small div:nth-child(6) {
    top: 198px
}

.build-crafted-box-bubbles-small div:nth-child(7),
.build-crafted-box-bubbles-small div:nth-child(8) {
    bottom: 2px
}

.build-crafted-box-bubbles-big {
    width: 432px;
    height: 432px;
    top: -8px;
    left: -30px
}

.build-crafted-box-bubbles-big div {
    width: 48px;
    height: 48px
}

.build-crafted-box-bubbles-big div:after {
    background-color: #e2e8ff03;
    box-shadow: 0 0 0 1px #e2e8ff0a;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%
}

.build-crafted-box-bubbles-big div:nth-child(1) {
    top: 0
}

.build-crafted-box-bubbles-big div:nth-child(5) {
    bottom: 0
}

.build-crafted-box-bubbles-big div:nth-child(1),
.build-crafted-box-bubbles-big div:nth-child(5) {
    left: 193px
}

.build-crafted-box-bubbles-big div:nth-child(2),
.build-crafted-box-bubbles-big div:nth-child(8) {
    top: 44px
}

.build-crafted-box-bubbles-big div:nth-child(3),
.build-crafted-box-bubbles-big div:nth-child(7) {
    top: 191px
}

.build-crafted-box-bubbles-big div:nth-child(4),
.build-crafted-box-bubbles-big div:nth-child(6) {
    top: 340px
}

.build-crafted-box-bubbles-big div:nth-child(2),
.build-crafted-box-bubbles-big div:nth-child(4) {
    right: 44px
}

.build-crafted-box-bubbles-big div:nth-child(3) {
    right: 0
}

.build-crafted-box-bubbles-big div:nth-child(6),
.build-crafted-box-bubbles-big div:nth-child(8) {
    left: 44px
}

.build-crafted-box-logo {
    width: 64px;
    height: 64px;
    position: absolute;
    top: 176px;
    left: 154px
}

@media (min-width: 996px) {
    .build-visible .build-crafted-box-circles-gradient {
        animation: build-crafted-box-circles 10s linear infinite
    }

    .build-visible .build-crafted-box-brands {
        animation: build-crafted-box-brands 80s linear infinite
    }

    .build-visible .build-crafted-box-brands-item {
        animation: build-crafted-box-brands 80s linear infinite reverse
    }

    .build-visible .build-crafted-box-bubbles-small {
        animation: build-crafted-box-brands 120s linear infinite reverse
    }

    .build-visible .build-crafted-box-bubbles-big {
        animation: build-crafted-box-brands 160s linear infinite
    }
}

@keyframes build-crafted-box-circles {
    0% {
        opacity: .32;
        transform: translateZ(0) rotate(0)
    }

    50% {
        opacity: 1;
        transform: translateZ(0) rotate(.5turn)
    }

    to {
        opacity: .32;
        transform: translateZ(0) rotate(1turn)
    }
}

@keyframes build-crafted-box-brands {
    to {
        transform: translateZ(0) rotate(-1turn)
    }
}

@media (max-width: 996px) {
    .build-crafted-box {
        left: -17px;
        position: relative
    }
}

.build-features-item-description,
.build-features-heading-description,
.build-features-heading-title {
    font-feature-settings: "ss01"on, "cv10"on, "calt"off, "liga"off
}

.build-features-heading-title {
    font-size: 23px;
    line-height: 28px
}

.build-features-heading-title {
    font-weight: 500
}

.build-features-heading-description {
    font-size: 23px;
    line-height: 24px
}

.build-features-item-description {
    font-size: 14px;
    line-height: 20px;
    text-align: left;
}

.build-features {
    margin-top: 60px
}

.build-features-heading {
    text-align: center
}
.mt-45 {
    margin-top: 45px;
}

.build-features-heading svg {
    margin: 0 auto 8px;
    width: 28px;
    height: 28px
}

.build-features-heading-title.custom {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 21px;
}

.build-features-heading-title.custom img {
    margin-right: 15px;
}

.build-features-heading-title {
    color: #e2e8ff;
    margin-bottom: 4px
}

.build-features-heading-title span:after {
    content: " - "
}

.build-features-heading-description {
    margin-bottom: 48px;
    color: #e2e8ff8c
}

.build-features .swiper-wrapper {
    display: grid;
    grid-column-gap: 24px;
    -moz-column-gap: 24px;
    column-gap: 24px;
    grid-template-columns: repeat(3, 1fr)
}

.build-features-item {
    padding: 20px;
    position: relative
}

.build-features-item:before {
    background: rgba(226, 232, 255, .1215686275);
    height: 24px;
    width: 1px;
    position: absolute;
    top: 18px;
    left: 0;
    content: ""
}

.build-features-item-title {
    display: flex;
    align-items: center;
    color: #e2e8ff;
    margin-bottom: 4px
}

.build-features-item-title svg {
    margin-right: 6px;
    width: 20px;
    height: 20px
}

.build-features-item-description {
    color: #e2e8ff8c
}

@media (max-width: 996px) {
    .build-features {
        margin-top: 64px
    }

    .build-features-heading {
        margin-bottom: 32px
    }

    .build-features-heading-title {
        max-width: 220px;
        margin: 0 auto
    }

    .build-features-heading-title span:after {
        content: "";
        display: block
    }

    .build-features-heading-description {
        max-width: 350px;
        margin: 0 auto
    }

    .build-features .swiper-wrapper {
        display: flex;
        margin: 0 auto;
        -moz-column-gap: 0;
        column-gap: 0
    }

    .build-features .swiper {
        width: 350px
    }

    .build-features-item {
        width: 300px
    }
}
</style>