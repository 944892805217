<template>
    <section class="lazy-background-image lazy-background-image-backgroundImage lazy-background-image-loaded crafted custom"
        style="--background-image: url('/q-d783587d.png');">
        <div class="square-frames">
            <div class="square-frames-item">
                <div></div>
            </div>
            <div class="square-frames-item">
                <div></div>
            </div>
            <div class="square-frames-item">
                <div></div>
            </div>
        </div>
        
        <div class="container-sm">
            <div class="section-header section-header-center">
                <h2 class="section-header-title section-header-title-h3">
                    <div class="section-header-title-desktop"><span>Онлайн Чат, Поддержка</span><span
                        ><b>база знаний и документация</b></span></div>
                    <div class="section-header-title-mobile"><span>Онлайн Чат, Поддержка</span><span
                        ><b>база знаний и документация</b></span></div>
                    
                </h2>
                <p class="section-header-description custom-2">Круглосуточная поддержка клиентов Coin Fuze!</p>
            </div>
            
        </div>
        <div class="chat">
            <img src="/chat-img.png" alt="">
        </div>
    </section>
</template>

<script>
export default {
    name: 'OnlineChatComponent'
}
</script>

<style>
.custom-2 {
    text-align: center;
}
.chat {
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.crafted.custom {
    height: 585px;
}
</style>