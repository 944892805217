<template>
    <section class="lazy-background-image lazy-background-image-backgroundImage lazy-background-image-loaded crafted clients-review"
        style="--background-image: url('/q-d783587d.png');">
        <div class="square-frames">
            <div class="square-frames-item">
                <div></div>
            </div>
            <div class="square-frames-item">
                <div></div>
            </div>
            <div class="square-frames-item">
                <div></div>
            </div>
        </div>
        
        <div class="container-sm">
            <div class="section-header section-header-center">
                <h2 class="section-header-title section-header-title-h3">
                    <div class="section-header-title-desktop"><span
                        ><b>Что говорят о нас клиенты?</b></span></div>
                    <div class="section-header-title-mobile"><span
                        ><b>Что говорят о нас клиенты?</b></span></div>
                    
                </h2>
                <p class="section-header-description custom text-center">Мы делаем все возможное, чтобы предоставить вам самые лучшие впечатления</p>
            </div>

            <div class="trustpilot-container">
                <img src="/trustpilot-logo.png" alt="trustpilot logo" class="img-fluid">
                <div class="trustpilot-wrap">
                    <span class="title">Excellent</span>
                    <img src="/rating.png" alt="trustpilot rating" class="img-fluid">
                    <span class="based-text">Based on <strong>456 reviews</strong></span>
                    <img src="/trustpilot-logo.png" alt="trustpilot logo" class="img-fluid mini-logo">
                </div>
            </div>
            </div>
                <div class="trustpilot-slider">
                    <div>
                        <swiper
                        :slides-per-view="3"
                        :space-between="30"
                        :loop="false"
                        :navigation="true"
                        >
                            <swiper-slide
                                v-for="item in reviews"
                                :key="item"
                                class="slider__item"
                            >
                                <img src="/quote-icon.png" alt="quote icon">
                                <h5 class="title__slider">{{ item.name }}</h5>
                                <div class="rating_slider">
                                    <img :src="item.rating" alt="" class="img-fluid">
                                    <span>{{ item.date }}</span>
                                </div>
                                <p class="review__slider">{{ item.review }}</p>
                                <span class="tag__slider">{{ item.tag }}</span>
                            </swiper-slide>
                        </swiper>
                    </div>
                </div>
    </section>
</template>

<script>
import { Navigation } from 'swiper'
import { SwiperCore, Swiper, SwiperSlide } from 'swiper-vue2'
// Import Swiper styles
import 'swiper/swiper-bundle.css'

SwiperCore.use([Navigation])
export default {
    name: 'ClientsRerviewComponent',
    components: {
    Swiper,
    SwiperSlide
  },
  data: () => ({
        reviews: [
            {
                'name': 'Майкл Роббинсон',
                'rating': '/rating.png',
                'date': '2 days ago',
                'review': 'Низкая комиссия для исходящих транзакций, оперативность, доступность',
                'tag': 'Комиссии'
            },
            {
                'name': 'Майкл Роббинсон',
                'rating': '/rating.png',
                'date': '2 days ago',
                'review': 'Низкая комиссия для исходящих транзакций, оперативность, доступность',
                'tag': 'Комиссии'
            },
            {
                'name': 'Майкл Роббинсон',
                'rating': '/rating.png',
                'date': '2 days ago',
                'review': 'Низкая комиссия для исходящих транзакций, оперативность, доступность',
                'tag': 'Комиссии'
            },
            {
                'name': 'Майкл Роббинсон',
                'rating': '/rating.png',
                'date': '2 days ago',
                'review': 'Низкая комиссия для исходящих транзакций, оперативность, доступность',
                'tag': 'Комиссии'
            },
        ]
    }),

}
</script>

<style lang="scss">
.trustpilot-slider {
    margin-top: 30px;
    padding: 0 222px;
}
.slider__item {
    border: 1px solid #4b306b;
    border-radius: 15px;
    padding: 20px;
    background: linear-gradient(to top, #2a2e4c 0%, #0e0927 100%);

    .title__slider {
        font-size: 14px;
        font-weight: normal;
        text-align: left;
        margin: 10px 0;
    }
    .rating_slider {
        display: flex;
        align-items: center;
        span {
            font-size: 12px;
            margin-left: 10px;
            color: #b7bad0;
        }
    }
    .review__slider {
        font-size: 14px;
        color: #b7bad0;
        text-align: left;
        margin: 15px 0;

    }
    .tag__slider {
        font-size: 12px;
        display: flex;
        text-align: left;
    }
}
.swiper-button-prev:after, .swiper-button-next:after {
    color: #fff;
}
.swiper-button-next, .swiper-container-rtl .swiper-button-prev {
    // right: -11px;
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
.w-100 {
  width: 100%;
}
.ml-auto, .mx-auto {
  margin-left: auto;
}
.mr-auto, .mx-auto {
  margin-right: auto;
}
.clients-review {
    padding: 116px 0 0;
    &.clients-review {
        height: auto;
    }
}
.section-header-description.custom.text-center {
    justify-content: center;
    align-items: center;
}
.trustpilot-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.trustpilot-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    .title {
        font-size: 14px;
        margin-bottom: 10px;
    }
    .img-fluid {
        margin-bottom: 5px;
    }
    .based-text {
        font-size: 12px;
        color: #b7bad0;
        margin-bottom: 15px;
        strong {
            font-weight: bold;
            color: #fff;
            text-decoration: underline;
        }
    }
    .mini-logo {
        width: 70px;
    }
}
</style>