<template>
     <section class="lazy-background-image lazy-background-image-backgroundImage lazy-background-image-loaded crafted clients-review"
        style="--background-image: url('/q-d783587d.png');">
        <div class="square-frames">
            <div class="square-frames-item">
                <div></div>
            </div>
            <div class="square-frames-item">
                <div></div>
            </div>
            <div class="square-frames-item">
                <div></div>
            </div>
        </div>
        
        <div class="container-sm">
            <div class="section-header section-header-center">
                <h2 class="section-header-title section-header-title-h3">
                    <div class="section-header-title-desktop"><span
                        ><b>Подключайтесь, инвестируйте и получайте доход?</b></span></div>
                    <div class="section-header-title-mobile"><span
                        ><b>Подключайтесь, инвестируйте и получайте доход?</b></span></div>
                    
                </h2>
                <p class="section-header-description custom text-center">Благодаря экосистеме Coin Fuze вы можете начать инвестировать и получать прибыль всего за несколько простых шагов. </p>
                <a href="#" class="investing__link">Подключиться →</a>
            </div>
            <div class="investing-step">
                <p class="invest-title">Ваши Первые шаги к финансовой независимости<img src="/invest-title-icon.png" class="img-fluid" alt=""></p>
                <img src="/investing-img.png" class="img-fluid" alt="">
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'InvestingComponent'
}
</script>

<style lang="scss">
.investing__link {
    background-image: linear-gradient(135deg, #482ee6 0%, #2150b9 33.33%, #47a88e 66.67%, #47a88e 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;
    font-size: 18px;
    font-weight: bold;
    margin-top: 15px;
}
.investing-step {
    border: 1px solid #4b306b;
    border-radius: 15px;
    padding: 20px;
    background: linear-gradient(to top, #2a2e4c 0%, #0e0927 100%);
}
.invest-title {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
    font-size: 21px;
    font-weight: bold;
    img {
        margin-left: 10px;
    }
}
</style>