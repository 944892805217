<template>
    <section class="lazy-background-image lazy-background-image-backgroundImage lazy-background-image-loaded crafted client-wrap">
        <div class="client-icons">
            <img src="/client-icon-1.png" alt="">
            <img src="/client-icon-2.png" alt="">
        </div>
        <div class="container-sm">
            <div class="section-header section-header-center">
                <h2 class="section-header-title section-header-title-h3 client-title">
                    <div class="section-header-title-desktop"><span>Отслеживайте рост ваших инвестиций</span><span
                        ><b>В личном кабинете клиента Coin Fuze</b></span></div>
                    <div class="section-header-title-mobile"><span>Отслеживайте рост ваших инвестиций</span><span
                        ><b>В личном кабинете клиента Coin Fuze</b></span></div>
                    
                </h2>
                <p class="section-header-description custom">
                    Удобный пользовательский интерфейс, подробная статистика эффективности, обучающие материалы все это и многое другое - гарантирует успешность ваших инвестиций.
                </p>
            </div>

        </div>
        <div class="graph">
            <img src="/client-screen.png" alt="">
        </div>
    </section>
</template>

<script>
export default {
    name: 'clientAreaComponent'
}
</script>

<style lang="scss">
.client-icons {
    display: flex;
    justify-content: center;
    img {
        &:last-child {
            margin-left: 10px;
        }
    }
}
.crafted {
    &.client-wrap {
        height: auto;
    }
}
.section-header-title-h3 {
    &.client-title {
        .section-header-title-desktop {
            font-size: 40px;
            span {
                b {

                }
            }
        }
    }
}
</style>