<template>
  <header class="header">
    <div class="header-inner">
      <a href="/" class="header-brand">
        <img alt="Coinfuze logo"
          src="../assets/logo.png" class="header-brand-logo">
      </a>
      <nav class="header-nav">
        <div
          style="width: 80px; transform: translateX(8px); opacity: 0; transition: opacity 450ms cubic-bezier(0.6, 0.6, 0, 1) 0s;"
          class="header-nav-background"></div>
        <ul style="width: 359px;">
          <li><a href="#" class="header-nav-item">About</a></li>
          <li><a href="#" class="header-nav-item header-nav-item-with-count">Careers</a></li>
          <li><a href="#" class="header-nav-item">Blog</a></li>
          <li><a href="#" class="header-nav-item">Changelog</a></li>
          <li class="header-nav-action-wrapper">
            <div class="header-nav-divider"></div><button class="header-nav-action">Регистрация</button>
          </li>
        </ul>
      </nav>
      <button class="header-action"><span class="header-action-text">Регистрация</span></button>
    </div>
  </header>
</template>

<script>
  export default {
    name: 'HeaderComponent',
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.header-action,.header-brand-name {
    font-feature-settings: "ss01" on,"cv10" on,"calt" off,"liga" off
}

.header-brand-name {
    font-size: 16px;
    line-height: 24px
}

.header-brand-name {
    font-weight: 500
}

.header-action {
    font-size: 14px;
    line-height: 20px
}

.header-action {
    font-weight: 500
}

.header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100vw;
    padding: 22px 24px
}

.header:before {
    width: 100%;
    height: 100%;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(255,255,255,.01);
    -webkit-backdrop-filter: blur(12px);
    backdrop-filter: blur(12px);
    transition: opacity .45s cubic-bezier(.6,.6,0,1)
}

.header-inner {
    max-width: 1328px;
    margin: 0 auto;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center
}

.header-inner:before {
    position: absolute;
    bottom: -22px;
    content: "";
    width: 100%;
    height: 1px;
    background: linear-gradient(to right,rgba(255,255,255,0) 0%,rgba(255,255,255,.1) 50%,rgba(255,255,255,0) 100%);
    transition: opacity .45s cubic-bezier(.6,.6,0,1)
}

.header-brand,.header-action {
    transition: transform .45s cubic-bezier(.6,.6,0,1),opacity .45s cubic-bezier(.6,.6,0,1)
}

.header-brand {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
    flex: 1
}

.header-brand-logo {
    height: 36px;
    margin-right: 12px
}

.header-brand-name {
    color: #fff
}

.header-action {
    background: linear-gradient(180deg,rgba(226,232,255,0) 0%,rgba(226,232,255,.12) 100%);
    padding: 8px 16px;
    position: relative;
    border-radius: 8px;
    z-index: 1
}

.header-action:before {
    background: linear-gradient(264.15deg,rgba(226,232,255,.2) -.4%,rgba(226,232,255,0) 100.4%),linear-gradient(0deg,rgba(226,232,255,.1),rgba(226,232,255,.1));
    border-radius: inherit;
    content: "";
    inset: 0;
    -webkit-mask: linear-gradient(#fff 0 0) content-box,linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box,linear-gradient(#fff 0 0);
    mask-composite: xor;
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    padding: 1px;
    pointer-events: none;
    position: absolute
}

.header-action:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    opacity: 0;
    border-radius: inherit;
    transition: opacity .45s cubic-bezier(.6,.6,0,1)
}

.header-action:hover:after {
    opacity: .025
}

.header-action-text {
    border-radius: 8px;
    background-image: linear-gradient(180deg,rgba(226,232,255,.6) 0%,#E2E8FF 100%);
    background-image: linear-gradient(180deg,color(display-p3 .8862745098 .9098039216 1/.6) 0%,color(display-p3 .8862745098 .9098039216 1/1) 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent
}

.header-scrolled:before,.header-scrolled .header-inner:before {
    opacity: 0
}

.header-scrolled .header-brand {
    opacity: 0;
    transform: translate(-12px)
}

.header-scrolled .header-action {
    opacity: 0;
    transform: translate(12px)
}

@media (max-width: 996px) {
    .header-nav {
        display:none
    }
}
.header-nav-item-count,.header-nav-action,.header-nav-item {
    font-feature-settings: "ss01" on,"cv10" on,"calt" off,"liga" off
}

.header-nav-action,.header-nav-item {
    font-size: 14px;
    line-height: 20px
}

.header-nav-action {
    font-weight: 500
}

.header-nav-item-count {
    font-size: 12px;
    line-height: 16px
}

.header-nav {
    z-index: 1;
    left: 50%;
    transform: translate(-50%);
    width: -moz-max-content;
    width: max-content;
    position: absolute;
    isolation: isolate;
    overflow: hidden;
    border-radius: 999px
}

.header-nav:before {
    background: rgba(5,5,30,.8);
    box-shadow: 0 12px 32px #05051e66,0 -8px 32px #e2e8ff1f inset;
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    border-radius: inherit;
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
    z-index: -1;
    opacity: 0;
    transition: opacity .45s cubic-bezier(.6,.6,0,1)
}

.header-nav ul {
    display: flex;
    padding: 4px;
    align-items: center;
    border-radius: inherit;
    border: 1px solid rgba(226,232,255,.1);
    position: relative;
    width: -moz-max-content;
    width: max-content;
    transition: width .45s cubic-bezier(.6,.6,0,1)
}

.header-nav ul li:not(:last-child) {
    margin-right: 2px
}

.header-nav-background {
    background-color: #dfdff50d;
    position: absolute;
    top: 4px;
    left: -3px;
    height: 38px;
    width: 80px;
    border-radius: inherit
}

.header-nav-background:before {
    border: 1px solid rgba(223,223,245,.12);
    content: "";
    left: 0;
    top: 0;
    position: absolute;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    border-radius: inherit;
    pointer-events: none
}

.header-nav-item {
    transition: background-color .45s cubic-bezier(.6,.6,0,1),border-color .45s cubic-bezier(.6,.6,0,1);
    color: #e2e8ffbf;
    padding: 8px 20px;
    position: relative;
    border-radius: inherit;
    display: flex;
    align-items: center;
    justify-content: center
}

.header-nav-item-count {
    margin-left: 4px;
    text-align: center;
    border-radius: 99px;
    padding: 2px 6px;
    min-width: 20px;
    background: rgba(226,232,255,.04)
}

.header-nav-action-wrapper {
    display: flex;
    align-items: center;
    position: absolute;
    right: 6px;
    transform: translate(200px);
    transition: transform .45s cubic-bezier(.6,.6,0,1)
}

.header-nav-divider {
    background-color: #e2e8ff14;
    height: 20px;
    width: 1px;
    margin: 0 16px 0 4px
}

.header-nav-action {
    white-space: nowrap;
    border-radius: 999px;
    padding: 6px 14px;
    color: #fff;
    position: relative;
    margin-right: 2px;
    background-image: linear-gradient(96.34deg,#926AFF 0%,#FF77B0 50%,#FFB367 100%);
    background-image: linear-gradient(96.34deg,color(display-p3 .5725490196 .4156862745 1/1) 0%,color(display-p3 1 .4666666667 .6901960784/1) 50%,color(display-p3 1 .7019607843 .4039215686/1) 100%)
}

.header-nav-action:before {
    background: linear-gradient(263.83deg,rgba(226,232,255,.2) -.4%,rgba(226,232,255,0) 100.4%),linear-gradient(0deg,rgba(226,232,255,.1),rgba(226,232,255,.1));
    border-radius: inherit;
    content: "";
    inset: 0;
    -webkit-mask: linear-gradient(#fff 0 0) content-box,linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box,linear-gradient(#fff 0 0);
    mask-composite: xor;
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    padding: 1px;
    pointer-events: none;
    position: absolute
}

.header-scrolled .header-nav:before {
    opacity: 1
}

.header-scrolled .header-nav-action-wrapper {
    transform: translate(0)
}

</style>