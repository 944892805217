<template>
   <section class="lazy-background-image lazy-background-image-backgroundImage lazy-background-image-loaded crafted client-wrap"
        style="--background-image: url('/q-d783587d.png');">
        <div class="square-frames">
            <div class="square-frames-item">
                <div></div>
            </div>
            <div class="square-frames-item">
                <div></div>
            </div>
            <div class="square-frames-item">
                <div></div>
            </div>
        </div>
        
        <div class="container-sm">
            <div class="section-header section-header-center">
                <h2 class="section-header-title section-header-title-h3 client-title">
                    <div class="section-header-title-desktop"><span>Наше видение и дорожная карта</span><span
                        ><b>Coin Fuze Команда</b></span></div>
                    <div class="section-header-title-mobile"><span>Наше видение и дорожная карта</span><span
                        ><b>Coin Fuze Команда</b></span></div>
                    
                </h2>
            </div>
            
            <div class="card card-left crafted-shortcuts">
                <picture class="lazy-image lazy-image-loaded crafted-shortcuts-dots" on:qvisible="q-8597889a.js#_hW[0]"
                >
                    <source media="(max-width: 996px)" srcset="/q-4327bae2.png" q:id="36"><img alt="Background dots"
                        decoding="async" height="1" loading="lazy" src="/q-ede3aff2.png" style="" width="1"
                        on:load="q-8597889a.js#s_7Oorj5iYnHM[0 1]" q:id="37"></picture>
                
                <div class="card-heading">
                        <img src="/vision-icon.png" alt="">
                    
                        <div class="card-title">
                            Наше видение в Coin Fuze 
                        </div>
                    <div class="card-description">
                        Мы создали доступное и инновационное решение для инвесторов, предоставляя им возможность получать стабильный доход от инвестиций в криптовалюту .  <br/>
                        Мы стремимся обеспечить наших клиентов современными и надежными инструментами, которые помогут им достичь финансового успеха и свободы.  <br/>
                        Мы верим в простоту, прозрачность и безопасность в мире финансов. И наше видение - это демократизация инвестиций для каждого!  
                    </div>
                </div>
        
            </div>
        </div>
        <div class="team-container">
            <div class="team-item">
                <img src="/review-img-1.png" alt="">
                <div class="d-flex flex-column">
                    <h4 class="about-grid-title">
                        Александр Лангсер
                    </h4>
                    <p class="about-grid-text">Соучредитель <br />Директор Coin Fuze</p>
                </div>
            </div>
            <div class="team-item">
                <img src="/review-img-2.png" alt="">
                <div class="d-flex flex-column">
                    <h4 class="about-grid-title">
                        Ниил Садик
                    </h4>
                    <p class="about-grid-text">Соучредитель <br/>Создатель ATS-250d.</p>
                </div>
            </div>
            <div class="team-item">
                <img src="/review-img-3.png" alt="">
                <div class="d-flex flex-column">
                    <h4 class="about-grid-title">
                        Эмма Робинсон
                    </h4>
                    <p class="about-grid-text">Руководитель отдела инноваций</p>
                </div>
            </div>
            <div class="team-item">
                <img src="/review-img-4.png" alt="">
                <div class="d-flex flex-column">
                    <h4 class="about-grid-title">
                        Марк Киннер
                    </h4>
                    <p class="about-grid-text">Создатель ATS-250d. <br/>Разработчик</p>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'TeamCoinfuzeComponent'
}
</script>

<style lang="scss">
.team-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 4 колонки */
    gap: 40px 10px; /* Промежуток между элементами */
    justify-content: space-between; /* Равномерное распределение по горизонтали */
    padding: 20px 90px; /* Отступы для красоты */
}
@media (max-width: 576px) {
  .team-container {
    grid-template-columns: 1fr;
    padding: 20px; /* Отступы для красоты */
  }
}

.team-item {
    position: relative;
    color: #ffffff;
    z-index: 999;
    display: flex;
    align-items: center;
    .flex-column {
        padding: 0 20px;
        align-items: start;
        .about-grid-title, .about-grid-text {
            text-align: left;
        }
    }
}
.flex-column {
    flex-direction: column;
}
</style>