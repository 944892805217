<template>
    <section class="hero">
        <div class="hero-background">
            <div class="hero-background-circles">
                <div class="hero-background-circle"></div>
                <div class="hero-background-circle"></div>
                <div class="hero-background-circle"></div>
            </div>
            <div class="hero-background-circle-lights">
                <img alt="Hero background circle lights" src="/q-37675410.png" style="opacity: 1; transform: translate(295.307px, 62.4095px);">
                <img alt="Hero background circle lights" src="/q-37675410.png" style="opacity: 1; transform: translate(5.54645px, 36.5292px);">
            </div>
            <div style="--background-image:url('/q-0e379a5f.png')" class="hero-background-dots">
                <div style="--mask-image:url('/q-cc423fbc.png')" class="hero-background-dots-fill">
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>

        <div class="section-header section-header-center">
            <a href="#" class="section-header-badge">
                <span class="section-header-badge-text">
                    Автоматическая систему торговли
                </span>
            </a>
            <h1 class="section-header-title section-header-title-h1">
                <div class="section-header-title-desktop">
                    <span>Зарабатывай на алгоритмической торговли</span>
                    <span>
                        <b>вместе с Coinfuze</b>
                    </span>
                </div>
                <div class="section-header-title-mobile">
                    <span>Зарабатывай на </span>
                    <span>алгоритмической торговли</span>
                    <span><b>вместе с Coinfuze</b></span>
                </div>
            </h1>
            <p class="section-header-description">Стабильный и финансовый результат вне зависимости от условий рынка</p>
        </div>
        <div class="hero-action">
            <div class="hero-action-form">
                <div class="hero-action-input">
                    <input placeholder="Email address..." type="email" >
                    <div class="hero-action-input-invalid-text">Invalid email address... Try again.</div>
                </div>
                <button aria-label="Регистрация" class="hero-action-button">
                    Регистрация
                    <div class="hero-action-button-frames">
                        <div class="hero-action-button-frame">
                            <div></div>
                        </div>
                        <div class="hero-action-button-frame">
                            <div></div>
                        </div>
                        <div class="hero-action-button-frame">
                            <div></div>
                        </div>
                    </div>
                </button>
            </div>
            <div class="hero-action-success-message"><span>You've joined the waitlist!</span></div>
        </div>
    </section>
</template>

<script>
/* eslint-disable */
export default {
    name: 'BannerComponent',
    data: () => ({
        e: 0,
        i: .02
    }),
    mounted() {
        this.animated()
    },
    methods: {
        animated() {
            const o = 150 + 150 * Math.cos(this.e),
                  l = 50 + 50 * Math.sin(this.e);
            document.querySelectorAll('.hero-background-circle-lights img').forEach((item, key) => {
                if(key === 0 ) {
                    item.style.transform = `translate(${o}px, ${l}px)`
                } else {
                    item.style.transform = `translate(${150 + 150 * Math.cos(-this.e - Math.PI / 1.2)}px, ${50 + 50 * Math.sin(-this.e - Math.PI / 1.2)}px)`
                }
            })
            this.e += this.i
            window.requestAnimationFrame(() => this.animated());
        }
    }
}
</script>

<style>
.hero {
    position: relative;
    width: 100%;
    padding: 144px 20px 0;
    contain: layout;
}
.hero-background {
    position: absolute;
    top: 160px;
    left: 50%;
    transform: translate(-50%);
    width: 1000px;
    display: flex;
    justify-content: center
}

.hero-background-circles,
.hero-background-circle-lights {
    width: 100%;
    position: absolute;
    top: 0
}

.hero-background-circle-lights img {
    width: 716px;
    height: 558px;
    position: absolute;
    top: 90px;
    opacity: 0;
    transition: opacity .45s cubic-bezier(.6, .6, 0, 1)
}

.hero-background-circle-lights img:nth-child(1) {
    z-index: 1;
    left: -20px
}

.hero-background-circle-lights img:nth-child(2) {
    z-index: 4;
    top: 220px;
    left: -20px
}

.hero-background-circle {
    border-radius: 50%;
    position: absolute;
    width: 100%;
    background-color: #05051e
}

.hero-background-circle:before {
    background: linear-gradient(to top, transparent 70%, rgba(255, 255, 255, .05) 100%);
    border-radius: inherit;
    content: "";
    inset: 0;
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask-composite: xor;
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    padding: 1px;
    pointer-events: none;
    position: absolute
}

.hero-background-circle:nth-child(1) {
    height: 1000px;
    top: 0
}

.hero-background-circle:nth-child(2) {
    height: 820px;
    top: 164px;
    z-index: 3
}

.hero-background-circle:nth-child(3) {
    height: 720px;
    top: 296px;
    z-index: 5
}

.hero-background-circle:after {
    content: "";
    border-radius: inherit;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    background: linear-gradient(to top, transparent 90%, rgba(255, 255, 255, .05) 100%)
}

.hero-background-dots {
    background-image: var(--background-image);
    background-size: cover;
    width: 1336px;
    height: 706px;
    left: -168px;
    position: absolute;
    top: 37px
}

.hero-background-dots-fill {
    -webkit-mask-image: var(--mask-image);
    mask-image: var(--mask-image);
    -webkit-mask-size: cover;
    mask-size: cover;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between
}

.hero-background-dots-fill div {
    transform: translateY(706px);
    animation: hero-background-dot 10s cubic-bezier(.6, .6, 0, 1) infinite;
    width: 258px;
    height: 400px;
    background-image: linear-gradient(135deg, #9E7AFF 0%, #FE8BBB 33.33%, #FFBD7A 66.67%, #F8EAC3 100%);
    background-image: linear-gradient(135deg, color(display-p3 .6196078431 .4784313725 1/1) 0%, color(display-p3 .9960784314 .5450980392 .7333333333/1) 33.33%, color(display-p3 1 .7411764706 .4784313725/1) 66.67%, color(display-p3 .9725490196 .9176470588 .7647058824/1) 100%)
}

@keyframes hero-background-circle {

    0%,
    90.001%,
    to {
        transform: translateZ(0) translateY(500px)
    }

    90% {
        transform: translateZ(0) translateY(-100px)
    }
}

@keyframes hero-background-dot {

    0%,
    90.001%,
    to {
        transform: translateZ(0) translateY(706px)
    }

    90% {
        transform: translateZ(0) translateY(-400px)
    }
}

@media (max-width: 996px) {
    .hero-background-circle {
        width: 500px;
        left: 250px
    }

    .hero-background-circle:nth-child(1) {
        height: 500px;
        top: 0
    }

    .hero-background-circle:nth-child(2) {
        height: 410px;
        top: 82px;
        z-index: 3
    }

    .hero-background-circle:nth-child(3) {
        height: 360px;
        top: 148px;
        z-index: 5
    }

    .hero-background-dots {
        display: none
    }
}
.hero-action-input-invalid-text,
.hero-action-success-message,
.hero-action-input input {
    font-feature-settings: "ss01"on, "cv10"on, "calt"off, "liga"off
}

.hero-action-input input {
    font-size: 16px;
    line-height: 24px
}

.hero-action-success-message {
    font-size: 14px;
    line-height: 20px
}

.hero-action-input-invalid-text {
    font-size: 12px;
    line-height: 16px
}

.hero-action {
    position: relative;
    margin-top: 32px;
    z-index: 6
}

.hero-action-form {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    transition: transform .45s cubic-bezier(.6, .6, 0, 1), opacity .45s cubic-bezier(.6, .6, 0, 1)
}

.hero-action-input {
    width: 278px;
    border-radius: 8px;
    position: relative;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px)
}

.hero-action-input:before,
.hero-action-input:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    border-radius: inherit;
    left: 0;
    pointer-events: none;
    transition: opacity .2s cubic-bezier(.6, .6, 0, 1)
}

.hero-action-input:before {
    background: linear-gradient(180deg, rgba(226, 232, 255, 0) 0%, rgba(226, 232, 255, .06) 100%), rgba(226, 232, 255, .02)
}

.hero-action-input:after {
    background: linear-gradient(180deg, rgba(226, 232, 255, 0) 0%, rgba(226, 232, 255, .06) 100%), rgba(226, 232, 255, .08);
    opacity: 0
}

.hero-action-input:hover:before {
    opacity: 0
}

.hero-action-input:hover:after {
    opacity: 1
}

.hero-action-input:focus-within:before,
.hero-action-input:focus-within:after {
    opacity: 0 !important
}

.hero-action-input input {
    width: 100%;
    background-color: transparent;
    padding: 9px 14px;
    color: #e2e8ff;
    border-radius: inherit;
    transition: border-color .2s cubic-bezier(.6, .6, 0, 1), box-shadow .2s cubic-bezier(.6, .6, 0, 1);
    border: 1px solid rgba(226, 232, 255, .1)
}

.hero-action-input input::-moz-placeholder {
    color: #e2e8ff59;
    -moz-transition: opacity .2s cubic-bezier(.6, .6, 0, 1);
    transition: opacity .2s cubic-bezier(.6, .6, 0, 1)
}

.hero-action-input input::placeholder {
    color: #e2e8ff59;
    transition: opacity .2s cubic-bezier(.6, .6, 0, 1)
}

.hero-action-input input:focus {
    border-color: #e2e8ff3d
}

.hero-action-input input:focus::-moz-placeholder {
    opacity: 0
}

.hero-action-input input:focus::placeholder {
    opacity: 0
}

.hero-action-input-invalid-text {
    position: absolute;
    bottom: -18px;
    left: 14px;
    color: #ec485cb3;
    transform: translate(-15px);
    opacity: 0;
    transition: opacity .2s cubic-bezier(.6, .6, 0, 1), transform .2s cubic-bezier(.6, .6, 0, 1)
}

.hero-action-invalid .hero-action-input input {
    border-color: #d2283d52;
    box-shadow: 0 20px #d2283d3d
}

.hero-action-invalid .hero-action-input-invalid-text {
    transform: translate(0);
    opacity: 1
}

.hero-action-success-message {
    display: flex;
    width: 428px;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 8px;
    position: absolute;
    top: 0;
    left: calc(50% - 214px);
    background: linear-gradient(135deg, rgba(158, 122, 255, .1) 0%, rgba(254, 139, 187, .1) 33.33%, rgba(255, 189, 122, .1) 66.67%, rgba(248, 234, 195, .1) 100%);
    opacity: 0;
    transform: scale(1.1);
    pointer-events: none;
    transition: transform .45s cubic-bezier(.6, .6, 0, 1), opacity .45s cubic-bezier(.6, .6, 0, 1)
}

.hero-action-success-message:before {
    background: linear-gradient(135deg, rgba(158, 122, 255, .2) 0%, rgba(254, 139, 187, .2) 33.33%, rgba(255, 189, 122, .2) 66.67%, rgba(248, 234, 195, .2) 100%);
    border-radius: inherit;
    content: "";
    inset: 0;
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask-composite: xor;
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    padding: 1px;
    pointer-events: none;
    position: absolute
}

.hero-action-success-message span {
    background-image: linear-gradient(135deg, #9E7AFF 0%, #FE8BBB 33.33%, #FFBD7A 66.67%, #F8EAC3 100%);
    background-image: linear-gradient(135deg, color(display-p3 .6196078431 .4784313725 1/1) 0%, color(display-p3 .9960784314 .5450980392 .7333333333/1) 33.33%, color(display-p3 1 .7411764706 .4784313725/1) 66.67%, color(display-p3 .9725490196 .9176470588 .7647058824/1) 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent
}

.hero-action-success .hero-action-form {
    transform: scale(.9);
    opacity: 0
}

.hero-action-success .hero-action-success-message {
    transform: scale(1);
    opacity: 1;
    pointer-events: all
}

@media (max-width: 996px) {
    .hero-action {
        max-width: 350px;
        margin: 24px auto 0
    }

    .hero-action-form {
        flex-direction: column
    }

    .hero-action-input {
        width: 100%
    }

    .hero-action-input input {
        height: 48px
    }

    .hero-action-success-message {
        width: 350px;
        left: calc(50% - 175px)
    }

    .hero-action-invalid .hero-action-button {
        margin-top: 20px
    }
}
.section-header-title-h5,
.section-header-title-h4,
.section-header-title-h3,
.section-header-title-h2,
.section-header-title-h1 {
    font-feature-settings: inherit;
    font-weight: 500;
    font-family: Onest, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif
}

.section-header-title-h1 {
    font-size: 58px;
    line-height: 80px;
    letter-spacing: -1.44px
}

.section-header-title-h2 {
    font-size: 56px;
    line-height: 64px
}

.section-header-title-h3 {
    font-size: 48px;
    line-height: 56px
}

.section-header-title-h4 {
    font-size: 40px;
    line-height: 48px
}

.section-header-title-h5 {
    font-size: 32px;
    line-height: 40px;
    font-weight: 500
}

.section-header-badge,
.section-header-title-h4+.section-header-description,
.section-header-description {
    font-feature-settings: "ss01"on, "cv10"on, "calt"off, "liga"off
}

.section-header-description {
    font-size: 18px;
    line-height: 28px
}

.section-header-title-h4+.section-header-description {
    font-size: 16px;
    line-height: 24px
}

.section-header-badge {
    font-size: 14px;
    line-height: 20px
}

@media (max-width: 996px) {
    .section-header-title-h1 {
        font-size: 44px;
        line-height: 52px
    }
}

@media (max-width: 996px) {
    .section-header-title-h2 {
        font-size: 40px;
        line-height: 48px
    }
}

@media (max-width: 996px) {
    .section-header-title-h3 {
        font-size: 36px;
        line-height: 40px
    }
}

@media (max-width: 996px) {

    .section-header-title-h5,
    .section-header-title-h4 {
        font-size: 32px;
        line-height: 40px
    }
}

.section-header {
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: column
}

.section-header-badge {
    border-radius: 999px;
    width: -moz-max-content;
    width: max-content;
    margin: 0 auto 16px;
    padding: 4px 14px;
    background: linear-gradient(135deg, rgba(202, 183, 255, .04) 0%, rgba(255, 155, 197, .04) 50%, rgba(255, 202, 149, .04) 100%);
    position: relative;
    box-shadow: 0 -4px 12px #d49eff1f inset;
    -webkit-backdrop-filter: blur(6px);
    backdrop-filter: blur(6px);
    transition: box-shadow .45s cubic-bezier(.6, .6, 0, 1)
}

.section-header-badge:before {
    background: linear-gradient(135deg, rgba(202, 183, 255, .2) 0%, rgba(255, 155, 197, .2) 50%, rgba(255, 202, 149, .2) 100%);
    border-radius: inherit;
    content: "";
    inset: 0;
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask-composite: xor;
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    padding: 1px;
    pointer-events: none;
    position: absolute
}

.section-header-badge-text {
    background-image: linear-gradient(135deg, #482ee6 0%, #2150b9 33.33%, #47a88e 66.67%, #47a88e 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent
}

.section-header a.section-header-badge:hover {
    box-shadow: 0 -4px 12px #d49eff4d inset
}

.section-header-title {
    max-width: -moz-max-content;
    max-width: max-content;
    width: 100%
}

.section-header-title span {
    background-image: linear-gradient(180deg, rgba(240, 238, 249, .8) 0%, #E2E8FF 100%);
    background-image: linear-gradient(180deg, color(display-p3 .9411764706 .9333333333 .9764705882/.8) 0%, color(display-p3 .8862745098 .9098039216 1/1) 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;
    display: block
}

.section-header-title span b {
    font-weight: inherit;
    background-image: linear-gradient(135deg, #482ee6 0%, #2150b9 33.33%, #47a88e 66.67%, #47a88e 100%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent
}

.section-header-title-mobile {
    display: none
}

.section-header-title-h1+.section-header-description {
    margin-top: 20px
}

.section-header-title-h3+.section-header-description {
    margin-top: 12px
}

.section-header-title-h4+.section-header-description {
    margin-top: 8px
}

.section-header-description {
    color: #e2e8ffbf;
    margin-top: 16px;
    max-width: 455px
}

.section-header-center {
    text-align: center;
    align-items: center
}

@media (max-width: 996px) {
    .section-header .section-header-titles:not(.section-header .section-header-titles-h1, .section-header .section-header-titles-h2)+.section-header-description {
        font-size: 16px
    }

    .section-header br,
    .section-header-title-desktop {
        display: none
    }

    .section-header-title-mobile {
        display: block
    }

    .section-header-description {
        margin-top: 12px;
        max-width: 455px
    }
}
.hero-action-button {
    font-feature-settings: "ss01"on, "cv10"on, "calt"off, "liga"off
}

.hero-action-button {
    font-size: 16px;
    line-height: 24px
}

.hero-action-button {
    font-weight: 500
}

.hero-action-button {
    padding: 10px 20px;
    color: #fff;
    position: relative;
    border-radius: 8px;
    background-image: linear-gradient(135deg, #482ee6 0%, #2150b9 33.33%, #47a88e 66.67%, #47a88e 100%);
    transition: transform .2s cubic-bezier(.6, .6, 0, 1), margin-top .2s cubic-bezier(.6, .6, 0, 1)
}

.hero-action-button:before {
    background: linear-gradient(to bottom, rgba(255, 255, 255, .32), transparent);
    border-radius: inherit;
    content: "";
    inset: 0;
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask-composite: xor;
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    padding: 1px;
    pointer-events: none;
    position: absolute
}

.hero-action-button:after {
    box-shadow: 0 0 #000019, 0 9px 21px #000019fa, 0 37px 37px #000019d9, 0 84px 50px #00001980, 0 149px 60px #00001926, 0 233px 65px #00001905, 0 -3px #49023933 inset;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    z-index: 1;
    transition: box-shadow .2s cubic-bezier(.6, .6, 0, 1), background-color .2s cubic-bezier(.6, .6, 0, 1)
}

.hero-action-button:hover:after {
    background-color: #ffffff1a
}

.hero-action-button:active {
    transform: translateY(1.5px)
}

.hero-action-button:active:after {
    box-shadow: 0 0 #000019, 0 9px 21px #000019fa, 0 37px 37px #000019d9, 0 84px 50px #00001980, 0 149px 60px #00001926, 0 233px 65px #00001905, 0 0 #49023933 inset
}

.hero-action-button:active .hero-action-button-frames {
    transform: translateY(-1.5px)
}

.hero-action-button-frames {
    pointer-events: none;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transition: transform .2s cubic-bezier(.6, .6, 0, 1)
}

.hero-action-button-frame {
    position: absolute;
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask-composite: xor;
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    padding: 1px;
    isolation: isolate;
    overflow: hidden
}

.hero-action-button-frame:before {
    border-radius: inherit;
    content: "";
    inset: 0;
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask-composite: xor;
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    padding: 1px;
    pointer-events: none;
    position: absolute;
    background-image: linear-gradient(to right, #CAB7FF, #FE8BBB, #FFBD7A);
    background-image: linear-gradient(to right, color(display-p3 .7921568627 .7176470588 1/1), color(display-p3 .9960784314 .5450980392 .7333333333/1), color(display-p3 1 .7411764706 .4784313725/1))
}

.hero-action-button-frame:nth-child(1) {
    width: 139px;
    height: 52px;
    top: -4px;
    left: -4px;
    border-radius: 12px;
    z-index: 2
}

.hero-action-button-frame:nth-child(1) div {
    animation: hero-action-button139px 10s linear infinite
}

@keyframes hero-action-button139px {
    0% {
        transform: translateZ(0) translate(-15px, -15px)
    }

    8.547008547% {
        transform: translateZ(0) translate(-15px, -37px)
    }

    12.8205128205% {
        transform: translateZ(0) translate(-15px, -52px) rotate(45deg)
    }

    21.3675213675% {
        transform: translateZ(0) translate(15px, -67px) rotate(90deg)
    }

    42.735042735% {
        transform: translateZ(0) translate(94px, -67px) rotate(90deg)
    }

    47.0085470085% {
        transform: translateZ(0) translate(109px, -67px) rotate(135deg)
    }

    48.7179487179% {
        transform: translateZ(0) translate(124px, -67px) rotate(180deg)
    }

    55.5555555556% {
        transform: translateZ(0) translate(124px, -45px) rotate(180deg)
    }

    59.8290598291% {
        transform: translateZ(0) translate(124px, -30px) rotate(225deg)
    }

    64.1025641026% {
        transform: translateZ(0) translate(109px, -15px) rotate(270deg)
    }

    94.0170940171% {
        transform: translateZ(0) translate(15px, -15px) rotate(270deg)
    }

    98.2905982906% {
        transform: translateZ(0) translateY(-15px) rotate(315deg)
    }

    to {
        transform: translateZ(0) translate(-15px, -15px) rotate(360deg)
    }
}

.hero-action-button-frame:nth-child(1):before {
    opacity: .3
}

.hero-action-button-frame:nth-child(2) {
    width: 147px;
    height: 60px;
    top: -8px;
    left: -8px;
    border-radius: 16px;
    z-index: 1
}

.hero-action-button-frame:nth-child(2) div {
    animation: hero-action-button147px 10s linear infinite
}

@keyframes hero-action-button147px {
    0% {
        transform: translateZ(0) translate(-15px, -15px)
    }

    8.547008547% {
        transform: translateZ(0) translate(-15px, -45px)
    }

    12.8205128205% {
        transform: translateZ(0) translate(-15px, -60px) rotate(45deg)
    }

    21.3675213675% {
        transform: translateZ(0) translate(15px, -75px) rotate(90deg)
    }

    42.735042735% {
        transform: translateZ(0) translate(102px, -75px) rotate(90deg)
    }

    47.0085470085% {
        transform: translateZ(0) translate(117px, -75px) rotate(135deg)
    }

    48.7179487179% {
        transform: translateZ(0) translate(132px, -75px) rotate(180deg)
    }

    55.5555555556% {
        transform: translateZ(0) translate(132px, -45px) rotate(180deg)
    }

    59.8290598291% {
        transform: translateZ(0) translate(132px, -30px) rotate(225deg)
    }

    64.1025641026% {
        transform: translateZ(0) translate(117px, -15px) rotate(270deg)
    }

    94.0170940171% {
        transform: translateZ(0) translate(15px, -15px) rotate(270deg)
    }

    98.2905982906% {
        transform: translateZ(0) translateY(-15px) rotate(315deg)
    }

    to {
        transform: translateZ(0) translate(-15px, -15px) rotate(360deg)
    }
}

.hero-action-button-frame:nth-child(2):before {
    opacity: .2
}

.hero-action-button-frame:nth-child(2) div {
    animation-duration: 12s
}

.hero-action-button-frame:nth-child(3) {
    width: 155px;
    height: 68px;
    top: -12px;
    left: -12px;
    border-radius: 20px
}

.hero-action-button-frame:nth-child(3) div {
    animation: hero-action-button155px 10s linear infinite
}

@keyframes hero-action-button155px {
    0% {
        transform: translateZ(0) translate(-15px, -15px)
    }

    8.547008547% {
        transform: translateZ(0) translate(-15px, -53px)
    }

    12.8205128205% {
        transform: translateZ(0) translate(-15px, -68px) rotate(45deg)
    }

    21.3675213675% {
        transform: translateZ(0) translate(15px, -83px) rotate(90deg)
    }

    42.735042735% {
        transform: translateZ(0) translate(110px, -83px) rotate(90deg)
    }

    47.0085470085% {
        transform: translateZ(0) translate(125px, -83px) rotate(135deg)
    }

    48.7179487179% {
        transform: translateZ(0) translate(140px, -83px) rotate(180deg)
    }

    55.5555555556% {
        transform: translateZ(0) translate(140px, -45px) rotate(180deg)
    }

    59.8290598291% {
        transform: translateZ(0) translate(140px, -30px) rotate(225deg)
    }

    64.1025641026% {
        transform: translateZ(0) translate(125px, -15px) rotate(270deg)
    }

    94.0170940171% {
        transform: translateZ(0) translate(15px, -15px) rotate(270deg)
    }

    98.2905982906% {
        transform: translateZ(0) translateY(-15px) rotate(315deg)
    }

    to {
        transform: translateZ(0) translate(-15px, -15px) rotate(360deg)
    }
}

.hero-action-button-frame:nth-child(3):before {
    opacity: .1
}

.hero-action-button-frame:nth-child(3) div {
    animation-duration: 14s
}

.hero-action-button-frame div {
    width: 30px;
    height: 30px;
    position: absolute;
    bottom: -30px;
    left: 0;
    background: linear-gradient(to top, transparent, #fff);
    opacity: .3
}

@media (max-width: 996px) {
    .hero-action-button {
        width: 100%
    }

    .hero-action-button-frame:nth-child(1) {
        width: calc(100% + 8px);
        height: calc(100% + 8px);
        top: -4px;
        left: -4px
    }

    .hero-action-button-frame:nth-child(1) div {
        transform: translate(-50%, -50%) rotate(-180deg)
    }

    .hero-action-button-frame:nth-child(2) {
        width: calc(100% + 16px);
        height: calc(100% + 16px);
        top: -8px;
        left: -8px
    }

    .hero-action-button-frame:nth-child(2) div {
        transform: translate(-50%, -50%) rotate(-90deg)
    }

    .hero-action-button-frame:nth-child(3) {
        width: calc(100% + 24px);
        height: calc(100% + 24px);
        top: -12px;
        left: -12px
    }

    .hero-action-button-frame:nth-child(3) div {
        transform: translate(-50%, -50%) rotate(0)
    }

    .hero-action-button-frame div {
        animation: none 0s ease 0s 1 normal none running !important;
        animation: initial !important;
        width: 500px;
        height: 500px;
        bottom: auto;
        bottom: initial;
        top: 50%;
        left: 50%;
        background: conic-gradient(transparent 10%, #fff 50%, transparent 50%)
    }
}

.hero-preview {
    min-width: 1216px;
    height: 766px;
    display: block;
    margin: 90px auto 0;
    position: relative;
    z-index: 1;
    border-radius: 18px 18px 0 0;
    isolation: isolate;
    overflow: hidden
}

.hero-preview:before {
    border: 1px solid rgba(226, 232, 255, .1);
    content: "";
    left: 0;
    top: 0;
    position: absolute;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    border-radius: inherit;
    pointer-events: none
}

.hero-preview:before {
    z-index: 2
}

.hero-preview:after {
    background: linear-gradient(180deg, rgba(5, 5, 30, 0) 0%, rgba(5, 5, 30, .7) 34.48%, #05051E 100%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    z-index: 5
}

.hero-preview img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    background-color: #05051e;
    position: relative;
    z-index: 1;
    border-radius: inherit
}

.hero-preview-animation {
    padding: 1px 1px 0;
    -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask-composite: xor;
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    position: absolute;
    inset: 0;
    z-index: 4;
    border-radius: inherit
}

.hero-preview-animation-line {
    width: 300px;
    height: 300px;
    position: absolute;
    animation: hero-preview-animation-line 15s linear infinite;
    bottom: -300px;
    left: 0;
    background-image: linear-gradient(to top, rgba(109, 46, 255, 0), rgba(158, 122, 255, .3490196078), rgba(254, 139, 187, .6980392157), #FFBD7A 99%, rgba(255, 189, 122, 0) 100%);
    background-image: linear-gradient(to top, color(display-p3 .4274509804 .1803921569 1/0), color(display-p3 .6196078431 .4784313725 1/.3490196078), color(display-p3 .9960784314 .5450980392 .7333333333/.6980392157), color(display-p3 1 .7411764706 .4784313725/1) 99%, color(display-p3 1 .7411764706 .4784313725/0) 100%)
}

.hero-preview-animation-line:nth-child(2) {
    animation-delay: 4s
}

@keyframes hero-preview-animation-line {
    0% {
        transform: translateZ(0) translate(-150px)
    }

    20% {
        transform: translateZ(0) translate(-150px, -766px)
    }

    21% {
        transform: translateZ(0) translate(-150px, -766px) rotate(45deg)
    }

    30% {
        transform: translateZ(0) translate(150px, -916px) rotate(90deg)
    }

    46% {
        transform: translateZ(0) translate(916px, -916px) rotate(90deg)
    }

    47% {
        transform: translateZ(0) translate(916px, -916px) rotate(135deg)
    }

    54% {
        transform: translateZ(0) translate(1066px, -616px) rotate(180deg)
    }

    73% {
        transform: translateZ(0) translate(1066px) rotate(180deg)
    }

    to {
        transform: translateZ(0) translate(1066px) rotate(180deg)
    }
}

@media (max-width: 996px) {
    .hero-preview {
        margin-top: 32px
    }

    .hero-preview-animation-line {
        animation: none 0s ease 0s 1 normal none running;
        animation: initial
    }
}
</style>