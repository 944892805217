<template>
  <div id="app">
    <Header />
    <Banner />
    <About />
    <DecisionPrior />
    <PartnerProgram />
    <OnlineChat />
    <ClientArea />
    <TeamCoinfuze />
    <ClientsReview />
    <Investing />
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header'
import Banner from '@/components/Banner'
import About from '@/components/About'
import DecisionPrior from '@/components/DecisionPrior'
import PartnerProgram from '@/components/PartnerProgram'
import OnlineChat from '@/components/OnlineChat'
import ClientArea from '@/components/ClientArea'
import TeamCoinfuze from '@/components/TeamCoinfuze'
import ClientsReview from '@/components/ClientsReview.vue'
import Investing from '@/components/Investing.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'HomeView',
  components: {
    Header,
    Banner,
    About,
    DecisionPrior,
    PartnerProgram,
    OnlineChat,
    ClientArea,
    TeamCoinfuze,
    ClientsReview,
    Investing,
    Footer
  }
}
</script>

<style lang="scss">

</style>
