<template>
    <div>
    <section class="cta">
        <div class="hero-background">
            <div class="hero-background-circles">
                <div class="hero-background-circle"></div>
                <div class="hero-background-circle"></div>
                <div class="hero-background-circle"></div>
            </div>
            <div class="hero-background-circle-lights">
                <img alt="Hero background circle lights" src="/q-37675410.png" style="opacity: 1; transform: translate(295.307px, 62.4095px);">
                <img alt="Hero background circle lights" src="/q-37675410.png" style="opacity: 1; transform: translate(5.54645px, 36.5292px);">
            </div>
        </div>
        <div class="cta-logo">
            <div class="square-frames">
                <div class="square-frames-item">
                    <div></div>
                </div>
                <div class="square-frames-item">
                    <div></div>
                </div>
                <div class="square-frames-item">
                    <div></div>
                </div>
            </div>
            <img alt="Coinfuze logo" decoding="async" height="1" loading="lazy"
                src="/footer-logo.png" style="" width="1" class="lazy-image lazy-image-loaded cta-logo"
                on:load="q-8597889a.js#s_Pnsy9IR1cps[0 1]" on:qvisible="q-8597889a.js#_hW[2]" q:id="eh">
        </div>
        <div class="cta-button-wrapper">
            <button aria-label="Join waitlist" class="hero-action-button">
                Join waitlist
                <div class="hero-action-button-frames">
                    <div class="hero-action-button-frame">
                        <div></div>
                    </div>
                    <div class="hero-action-button-frame">
                        <div></div>
                    </div>
                    <div class="hero-action-button-frame">
                        <div></div>
                    </div>
                </div>
            </button>
        </div>
    </section>
    <footer class="footer" >
        <div class="footer-top">
            <div class="footer-container">
                <div class="footer-brand">
                    <img alt="Coinfuze logo" decoding="async" height="1" loading="lazy"
                        src="/logo.png" style="" width="1" class="lazy-image lazy-image-loaded"
                        on:load="q-8597889a.js#s_Pnsy9IR1cps[0 1]" on:qvisible="q-8597889a.js#_hW[2]" q:id="eo">
                    </div>
                <div class="footer-nav">
                    <div class="footer-nav-group" >
                        <div class="footer-nav-group-label">Product</div>
                        <ul>
                            <li><a href="#">Changelog</a></li>
                            <li><a href="#">Documentation</a></li>
                        </ul>
                    </div>
                    <div class="footer-nav-group">
                        <div class="footer-nav-group-label">Company</div>
                        <ul>
                            <li><a href="#">About</a></li>
                            <li><a href="#">Careers</a></li>
                            <li><a href="#">Blog</a></li>
                        </ul>
                    </div>
                    <div class="footer-nav-group" >
                        <div class="footer-nav-group-label">Contact</div>
                        <ul>
                            <li><a href="#">Discord</a></li>
                            <li><a href="#">Twitter</a></li>
                            <li><a href="#">Github</a></li>
                            <li><a href="#">Email</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-bottom">
            <div class="footer-container">
                <div class="footer-copyright">Copyright © 2023 Coinfuze All rights reserved.</div>
                <div class="footer-terms"><a href="#">Terms of Service</a></div>
            </div>
        </div>
    </footer>
</div>
</template>

<script>
export default {
    name: 'FooterComponent',
    data: () => ({
        e: 0,
        i: .02
    }),
    mounted() {
        this.animated()
    },
    methods: {
        animated() {
            const o = 150 + 150 * Math.cos(this.e),
                  l = 50 + 50 * Math.sin(this.e);
            document.querySelectorAll('.hero-background-circle-lights img').forEach((item, key) => {
                if(key === 0 ) {
                    item.style.transform = `translate(${o}px, ${l}px)`
                } else {
                    item.style.transform = `translate(${150 + 150 * Math.cos(-this.e - Math.PI / 1.2)}px, ${50 + 50 * Math.sin(-this.e - Math.PI / 1.2)}px)`
                }
            })
            this.e += this.i
            window.requestAnimationFrame(() => this.animated());
        }
    }
}
</script>

<style lang="scss">
.cta {
    position: relative;
    padding-top: 16px;
    width: 1440px;
    height: 600px;
    margin-top: 64px
}

.cta .section-header-description {
    max-width: none;
    max-width: initial
}

.cta-logo {
    position: relative;
    margin: 0 auto 14px;
    width: 132px
}

.cta-logo img {
    width: 96px;
    height: auto;
    position: absolute;
    top: 18px;
    left: 18px
}

.cta .square-frames {
    width: 132px;
    position: relative;
    z-index: 1;
    height: 132px
}

.cta .square-frames-item:nth-child(1) {
    border-radius: 28px;
    width: calc(100% - 24px);
    height: calc(100% - 24px)
}

.cta .square-frames-item:nth-child(2) {
    border-radius: 34px;
    width: calc(100% - 12px);
    height: calc(100% - 12px)
}

.cta .square-frames-item:nth-child(3) {
    border-radius: 40px;
    width: 100%;
    height: 100%
}

.cta .hero-background {
    top: 0
}

.cta-dots {
    width: 1312px;
    height: 298px;
    left: 64px;
    top: 58px;
    position: absolute
}

.cta-button-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 32px;
    position: relative;
    z-index: 2
}

@media (max-width: 996px) {
    .cta {
        height:-moz-max-content;
        height: max-content;
        padding-bottom: 88px;
        position: relative;
        width: 350px;
        margin-top: 96px
    }

    .cta:before {
        content: "";
        position: absolute;
        top: -112px;
        left: 0;
        width: 350px;
        opacity: .12;
        background: linear-gradient(to right,rgba(5,5,30,0),#E2E8FF,rgba(5,5,30,0));
        height: 1px
    }

    .cta-logo {
        width: 112px;
        height: 112px;
        margin-bottom: 30px
    }

    .cta-logo img {
        width: 72px;
        top: 20px;
        left: 20px
    }

    .cta .square-frames {
        width: 112px;
        height: 112px
    }

    .cta-button-wrapper {
        width: 326px;
        margin: 40px auto 0
    }

    .cta .section-header-description {
        max-width: 340px
    }
}
.footer-bottom,.footer-nav-group-label,.footer-nav a,.footer-brand {
    font-feature-settings: "ss01" on,"cv10" on,"calt" off,"liga" off
}

.footer-brand {
    font-size: 16px;
    line-height: 24px
}

.footer-brand {
    font-weight: 500
}

.footer-nav-group-label,.footer-nav a {
    font-size: 14px;
    line-height: 20px
}

.footer-nav-group-label {
    font-weight: 500
}

.footer-bottom {
    font-size: 12px;
    line-height: 16px
}

.footer {
    position: relative;
    content-visibility: auto;
    width: 100%
}

.footer-top .footer-container {
    position: relative;
    padding: 64px 0 100px
}

.footer-top .footer-container:before,.footer-top .footer-container:after {
    background: linear-gradient(to right,transparent,rgba(226,232,255,.12),transparent);
    content: "";
    position: absolute;
    height: 1px
}

.footer-top .footer-container:after {
    bottom: 0;
    left: 0;
    width: 100%
}

.footer-top .footer-container:before {
    top: 0;
    left: 50%;
    transform: translate(-50%);
    width: 1440px
}

.footer-container {
    max-width: 996px;
    margin: 0 auto;
    display: flex;
    width: 100%
}

.footer-top {
    display: flex
}

.footer-brand {
    display: flex;
    align-items: center;
    color: #fff;
    flex: 1;
    height: -moz-max-content;
    height: max-content
}

.footer-brand img {
    width: 114px;
    height: 32px;
    margin-right: 12px
}

.footer-nav {
    display: flex;
    gap: 24px
}

.footer-nav-group {
    width: 180px
}

.footer-nav-group-label {
    color: #e2e8ffbf;
    margin-bottom: 20px
}

.footer-nav li:not(:last-child) {
    margin-bottom: 20px
}

.footer-nav a {
    color: #e2e8ff8c;
    transition: color .45s cubic-bezier(.6,.6,0,1)
}

.footer-nav a:hover {
    color: #e2e8ffbf
}

.footer-bottom {
    color: #e2e8ff59;
    padding: 48px 0
}

.footer-copyright {
    flex: 1
}

.footer-terms {
    display: flex;
    gap: 32px
}

.footer-terms a {
    transition: color .45s cubic-bezier(.6,.6,0,1)
}

.footer-terms a:hover {
    color: #e2e8ffbf
}

@media (max-width: 996px) {
    .footer-brand {
        margin-bottom:40px
    }

    .footer-container {
        flex-direction: column;
        max-width: 350px
    }

    .footer-top .footer-container {
        padding: 56px 0
    }

    .footer-top .footer-container:before {
        top: 0;
        left: 0;
        transform: none;
        transform: initial;
        width: 100%
    }

    .footer-nav {
        flex-wrap: wrap;
        gap: 32px 0
    }

    .footer-nav-group {
        width: 165px
    }

    .footer-bottom {
        padding: 40px 0
    }

    .footer-bottom .footer-container {
        align-items: center;
        gap: 24px
    }
}
</style>