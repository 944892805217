<template>
    <div class="partners-bg">
        <section class="lazy-background-image lazy-background-image-backgroundImage lazy-background-image-loaded crafted"
            style="--background-image: url('/q-d783587d.png');">
            <div class="square-frames">
                <div class="square-frames-item">
                    <div></div>
                </div>
                <div class="square-frames-item">
                    <div></div>
                </div>
                <div class="square-frames-item">
                    <div></div>
                </div>
            </div>
            
            <div class="container-sm">
                <div class="section-header section-header-center">
                    <h2 class="section-header-title section-header-title-h3">
                        <div class="section-header-title-desktop"><span>Партнерская программа</span><span
                            ><b>Coin Fuze ценит активных клиентов</b></span></div>
                        <div class="section-header-title-mobile"><span>Партнерская программа</span><span
                            ><b>Coin Fuze ценит активных клиентов</b></span></div>
                        
                    </h2>
                    <p class="section-header-description custom"><span>Эксклюзивные возможности</span> <span>Поддержка и обучение</span> Проверенная бизнес-модель</p>
                </div>
                
                <div class="card card-left crafted-shortcuts">
                    <picture class="lazy-image lazy-image-loaded crafted-shortcuts-dots" on:qvisible="q-8597889a.js#_hW[0]"
                    >
                        <source media="(max-width: 996px)" srcset="/q-4327bae2.png" q:id="36"><img alt="Background dots"
                            decoding="async" height="1" loading="lazy" src="/q-ede3aff2.png" style="" width="1"
                            on:load="q-8597889a.js#s_7Oorj5iYnHM[0 1]" q:id="37"></picture>
                    
                    <div class="card-heading">
                        <div class="d-flex">
                            <img src="/partners-icon-1.png" alt="">
                        
                            <div class="card-title">
                                Партнерская программа от CoinFuze
                            </div>
                        </div>
                        <div class="card-description">
                            Еще один способ расширить ваши инвестиционные возможности и увеличить  дополнительный доход . <br/>
                            Как это работает: <br/>
                            Пригласите ваших друзей и знакомых в наш проект и получите бонусы.  
                            Или используете каналы онлайн-маркетинга для привлечения новых пользователей в нашу систему,  как только они активируют свой инвестиционный план, вы сразу получите вознаграждение.<br/>
                            <a href="#" class="partner-link">Создать акаунт<img src="/partner-link-icon.png" alt=""></a>
                        </div>
                    </div>
            
                </div>
            </div>
            <div class="graph">
                <img src="/partner-graph_.png" alt="">
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'PartnerProgramComponent'
}
</script>

<style lang="scss">
.graph {
    display: flex;
    align-items: center;
    justify-content: center;
}
.d-flex {
    display: flex;
    align-items: center;
    img {
        margin-right: 10px;
    }
}
.partner-link {
    font-size: 15px;
    margin-top: 20px;
    color: #fff;
    display: flex;
    align-items: center;
    img {
        margin-left: 10px;
    }
}
.section-header-description.custom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;
    width: 100%;
    font-size: 18px;
}
.partners-bg {
    background-image: url('./../../public/partner-bg.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center bottom;
}
.crafted {
    width: 1440px;
    height: 1140px;
    position: relative;
    padding: 116px 222px 0;
    
}

.crafted .container-sm {
    content-visibility: auto
}

.crafted .square-frames {
    margin: 0 auto 20px;
    position: relative
}

.crafted .section-header {
    margin-bottom: 40px
}

.crafted-boxes {
    display: flex;
    gap: 24px;
    margin-top: 24px
}

.crafted-boxes .crafted-cmd {
    flex: 1
}

.crafted-boxes .crafted-edge {
    width: 330px
}

@media (max-width: 996px) {
    .crafted {
        padding:84px 0 0;
        width: 548px;
        height: 2016px
    }

    .crafted-boxes {
        margin-top: 36px;
        flex-direction: column
    }

    .crafted-boxes .crafted-cmd {
        flex: initial
    }

    .crafted-boxes .crafted-edge {
        width: auto;
        width: initial
    }
}
</style>