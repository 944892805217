<template>
  <div class="home">
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  components: {
  }
}
</script>
